import './../App.css';
import HomeSide from './HomeSide.js';
import React, { useState ,useEffect,useContext} from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import { FaPen } from 'react-icons/fa';
import { Card} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { GlobalVariables } from './Functions.js';

const Aler = props => {
  //variabili globali
  const aGV= useContext(GlobalVariables)
  
  //definizioni per tabella
  const [aConfAler,setConfAler]=useState([]) ;
  
  //definizioni per modale
 
  //router
  const navigate = useNavigate(); 
  
  //funzioni Nuovo e Modifica 
  function handleBtnAlerNuovClick() {
      navigate('/AlerEdit', {state: {opzi:'I', row:-1,data:[]}});
  }
  function handleAlerEditClick(opzx,rowx){// DELE/MODI e numero index
      const datx=aConfAler[rowx.id]
      navigate('/AlerEdit', {state: {opzi:opzx, row:rowx.id,data:datx}});
  }

  function iconTable(cell, row, rowIndex) {
    return (
      <div>
      <button id="rowModi"  onClick={() => handleAlerEditClick("U",row)} className="btn btn-danger btn-sm " > <FaPen></FaPen></button>
      
      </div>   
    );
  }
 
  
  const columns = [
    {
      dataField: "OPZI",
      text: "Action",
      sort: false,
      formatter: iconTable,
      headerStyle: (colum, colIndex) => {
        return { width: '5%', textAlign: 'center' };
      }
    },
    {
      dataField: "SOCI",
      text: "Società",
      sort: true
     
    
    },
    {
      dataField: "CODX",
      text: "Codice",
      sort: true,
      style: {
        fontWeight: 'bold'
        //fontSize: '18px'
      },
      filter: textFilter({
        delay: 500, // default is 500ms
        style: {
          backgroundColor: '#ffffff'
        }
     
       
      })
     
    },
    {
      dataField: "DESX",
      text: "Descrizione", 
      sort: true,
      filter: textFilter({
        delay: 500, // default is 500ms
        style: {
          backgroundColor: '#ffffff'
        }
       
      })
     
    
    },
    {
      dataField: "STEP",
      text: "Step", 
      sort: true
    },
    {
      dataField: "TYPE",
      text: "Tipo", 
      sort: true
    },
    {
      dataField: "TO",
      text: "To", 
      sort: true
    },
    {
      dataField: "SUBJ",
      text: "Oggetto", 
      sort: true
    },
 
    {
      dataField: "RIF1",
      text: "Add.1"
    },
    {
      dataField: "RIF2",
      text: "Add.2"
    },
    {
      dataField: "RIF3",
      text: "Add.3" 
    }
    
  ]
  
  


  const cUrl=aGV.urll +"AMG_Alerts" //"https://amg.datapartners.ch/Amg/ws/AMG_WS/AMG_Roles"
  const raw = JSON.stringify({
       customer: aGV.customer, //"AMGDEMO", //"cust01",
       user: aGV.user, // "alberto.cappelli@libero.it", //"pippo",
       type: "S",
       keyc: "*|",
       data: "*|",
  });
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
  };
  useEffect(() => {
    fetch(cUrl,requestOptions)
    .then(response =>  { return response.json()  }) 
    .then( data => { 
             const aData=data.alerts //data[attx] 
           
             if(aData!=null){
               //console.log(aData.length)
               var aD=[]
               for (var i = 0; i < aData.length; i++) {
                    var row = aData[i];
                   
                    if (row!=null) {//console.log(row.code + " - " + row.description);
                        //console.log(row)
                        aD.push({
                            id: i,
                            OPZI: "",
                            SOCI: row.company,
                            CODX: row.code,
                            DESX: row.description,
                            STEP: row.step,
                            TYPE: row.type,
                            TO: row.too,
                            CC: row.cc,
                            SUBJ: row.subject,
                            BODY: row.body,
                            RIF1: row.rif1,
                            RIF2: row.rif2,
                            RIF3: row.rif3,
                        });
                        
                    }   
               } //loop
               setConfAler(aD)
             
            } //data is noy null
    })
    .catch( err =>  {  
            alert(err.message)
          
   })
},[]);
 
 
  return (    
    
    <div style={window.$stiTop} > 
        <div style={window.$stiLeft}>
            <HomeSide></HomeSide>
        </div> 
        <div style={window.$stiRight}>
           <h2  id="lbzAppTito">AMG Foundation Alerts</h2>
           <hr id="hrAppTito" />
            <Card className="shadow p-3 mb-5 bg-white rounded">
          
            


      <Card.Body>
      <button className="btn btn-danger btn-sm " style={{position: 'absolute', left: 0,top:0}}
      onClick={handleBtnAlerNuovClick}>Nuovo</button>
       <hr></hr>
    
      <div>
      
          <BootstrapTable
          
        bootstrap4
        keyField="id" 
        data={aConfAler}
        columns={columns}
        headerClasses="table tableheadergrey"
        filter={ filterFactory() } 
        filterPosition="top"
        hover
        condensed
      />
   
        </div>
    
        </Card.Body>
    </Card>
    </div>  
   


</div>  


  )  
             
}              
 
    
 

export default Aler;