import './../App.css';
import HomeSide from './HomeSide.js';
import React, { useState ,useEffect,useContext} from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import { FaPen } from 'react-icons/fa';
import { Card} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { GlobalVariables,convBlankPunt,trovaCost} from './Functions.js';
import Form from 'react-bootstrap/Form';
import { faHourglassEmpty } from '@fortawesome/free-solid-svg-icons';

const Uten = props => {
  //variabili globali
  const aGV= useContext(GlobalVariables)
  
  //definizioni per tabella
  const [aConfUten,setConfUten]=useState([]) ;
  
  // definizione check selezione
  const [checked, setChecked] = React.useState(false);
  //definizioni per modale
 
  //router
  const navigate = useNavigate(); 

  const handleChkSeleUtenClick = (e) => {
     //console.log(checked)
     if (checked==true){
        setChecked(false)
     }else{
        setChecked(true)
     } 
     //devo rilanciare la ricerca  
    //console.log(event)
 }
  
  //funzioni Nuovo e Modifica 
  function handleBtnUtenNuovClick() {
      navigate('/UtenEdit', {state: {opzi:'I', row:-1,data:[]}});
  }
  function handleUtenEditClick(opzx,rowx){// DELE/MODI e numero index
       const datx=aConfUten[rowx.id]
       navigate('/UtenEdit', {state: {opzi:opzx, row:rowx.id,data:datx}});
  }

  
 

  function iconTable(cell, row, rowIndex) {
    return (
      <div>
      <button id="rowModi"  onClick={() => handleUtenEditClick("U",row)} className="btn btn-danger btn-sm " > <FaPen></FaPen></button>
      
      </div>   
    );
  }
 
  
  const columns = [
    {
      dataField: "OPZI",
      text: "Action",
      sort: false,
      formatter: iconTable,
      headerStyle: (colum, colIndex) => {
        return { width: '5%', textAlign: 'center' };
      },
      
    },
    {
      dataField: "EMAI",
      text: "Email",
      sort: true,
     
      filter: textFilter({
        delay: 500, // default is 500ms
        style: {
          backgroundColor: '#ffffff'
        }
     
       
      })
     
    },
    {
      dataField: "DESX",
      text: "Nome", 
      sort: true,
      filter: textFilter({
        delay: 500, // default is 500ms
        style: {
            backgroundColor: '#ffffff'
        }
       
      })
     
    
    },
    {
      dataField: "RUOL", 
      text: "Ruolo",
      sort: true
     
    
    },
    {
      dataField: "LANG", 
      text: "Lingua",
      sort: true
     
    
    },
  
 
    {
      dataField: "CELL", 
      text: "Cellulare",
      sort: true
    }
   
    
  ]
  
  

  const cUrl=aGV.urll +"AMG_Users" //"https://amg.datapartners.ch/Amg/ws/AMG_WS/AMG_Roles"
  const raw = JSON.stringify({
       customer: aGV.customer, //"AMGDEMO", //"cust01",
       user: aGV.user, // "alberto.cappelli@libero.it", //"pippo",
       type: "S",
       keyc: "*|",
       data: "*|",
  });
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
  };
  useEffect(() => {
    //alert(checked)
    fetch(cUrl,requestOptions)
    .then(response =>  { return response.json()  }) 
    .then( data => { 
             const aData=data.users //data[attx] 
             if(aData!=null){
               //console.log(aData.length)
               var aD=[]
               for (var i = 0; i < aData.length; i++) {
                    var row = aData[i];
                    if (row!=null) {//console.log(row.code + " - " + row.description);
                      //console.log(row)
                      var cRuol=convBlankPunt(row.roles)
                      if (checked==false || cRuol=='STANDARD_USER'){
                         aD.push({
                            id: i,
                            EMAI: row.email,
                            DESX: row.description,
                            NICK: row.nickName,
                            LANG: row.language,
                            SOCI: convBlankPunt(row.companies),
                            SEDI: convBlankPunt(row.branches),
                            GRUP: convBlankPunt(row.groups),
                            RUOL: convBlankPunt(row.roles),
                            UTEC: row.userCreation,
                            DATC: row.dateCreation,
                            TELE: row.phone,
                            TEL2: row.phone2,
                            CELL: row.phoneCell,
                            AUT2: row.auth2,
                            RIF1: row.rif1,
                            RIF2: row.rif2,
                            RIF3: row.rif3,
                            PRID: row.privacyDate,
                            DARL: row.darkLight,
                            THEM: row.theme,
                            NOTI: row.notification  ,
                            CARD: row.cards
                        });
                      } 
                    }   
               } //loop
               setConfUten(aD)
             } //data is noy null
    })
    .catch( err =>  {  
            alert(err.message)
    })
},[checked]);
 
 
  return (    
    
    <div style={window.$stiTop} > 
        <div style={window.$stiLeft}>
            <HomeSide></HomeSide>
        </div> 
        <div style={window.$stiRight}>
           <h2  >AMG Foundation Utenti</h2>
           <hr id="hrAppTito" />
           <Card className="shadow p-3 mb-5 bg-white rounded">
              <Card.Body>
              <Form.Group>
                <button className="btn btn-danger btn-sm " style={{position: 'absolute', left: 0,top:0}}
                    onClick={handleBtnUtenNuovClick}>Nuovo</button>
                      <Form.Check 
                         inline
                         
                         type="switch"
                         id="custom-switch"
                         label="Solo da autorizzare"
                         onChange={handleChkSeleUtenClick}
                 
                       />
              
               </Form.Group>
                <hr></hr>

                <BootstrapTable
                   bootstrap4
                   keyField="id" 
                   data={aConfUten}
                   columns={columns}
                   headerClasses="table tableheadergrey"
                   filter={ filterFactory() } 
                   filterPosition="top"
                   hover
                   condensed
                />

                </Card.Body>
           </Card>
       </div>   
    </div>  // div globale
  
  )  //return 

}    //componente          
export default Uten;