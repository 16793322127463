import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter} from 'react-router-dom'
import { HashRouter} from 'react-router-dom'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-responsive-modal/styles.css';



window.$stiTop = {
    display:"flex",
    flexDirection:"row",
}; 
window.$stiLeft = {
    height: "100vh",
    backgroundColor: "#ffffff",
    flex:2,
};  
window.$stiRight = {
    flex:10,
    backgroundColor: "#f9f9f9",
}; 
  

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter> 
 
  
);

//PRE Matteo
//<React.StrictMode>
//    <HashRouter>
//    <App />
//    </HashRouter>
//    </React.StrictMode>

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//<BrowserRouter>
//    <App />
//    </BrowserRouter>

