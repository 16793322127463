import {  toast } from 'react-toastify';
import { createContext } from "react";
import * as CryptoJS from 'crypto-js';


export const GlobalVariables = createContext("");

export function getParamD( strx,parx ){ //legge parametro parx   dalla stringa strx
     //console.log(strx)
     parx = parx.replace(/[\[]/,"\\\[").replace(/[\]]/,"\\\]");
     var regexS = "[\\?&]"+parx+"=([^&#]*)";
     var regex = new RegExp( regexS );   
     var c= strx
     c=decodeURIComponent(c)
     //alert(c)
     var results = regex.exec(c);
     //alert("LLLLL"+window.location.href)
     if( results === null ){ 
       return "";
     } else {
       return results[1];  
     }   
}     
export function AMGCrypto(strx,tipx) { //tipx Cripta Decripta
   var CryptoJS = require("crypto-js");
   var c=""
   if (tipx==='C') {
    var c=CryptoJS.AES.encrypt(strx, "Teodolinda").toString();
    return c
   }  
   if (tipx==='D') {
    var decrypted = CryptoJS.AES.decrypt(strx, "Teodolinda");
    //var decryptedData=CryptoJS.AES.decrypt(strx, "Teodolinda");
    c=decrypted.toString(CryptoJS.enc.Utf8);
    //c=decryptedData.toString(CryptoJS.enc.Utf8);
    //console.log(c)
    return c
   }  
 }   
export function trovaCost(aGV,codx){ //codx=codice da trovare
   var i=0
   //console.log(aGV)
   //return
   var n=aGV.aConfCost.length
   var cValo="";
   //alert(n+"/"+codx)
   //return
   for (i=0; i<n; i++){
     //console.log(codx+"/"+aConfCost[i].CODX)
     if (aGV.aConfCost[i].CODX===codx) { 
           
           cValo=aGV.aConfCost[i].VALO
     
           return cValo
     }            
   } 
   return ""

}   
export function chiamaInvioFast(aGV,subx,bodx,tipx,toox,cccx,calx)   { //manda mail 
      //cusx=customer  subx=subject bodx=body  emax=email calx=callback
      //console.log(aGV)
      //return
      //alert(trovaCost(aGV,"MAILFROM"))
      var myHeaders = new Headers();  
      myHeaders.append("Content-Type", "application/json");
      var raw = JSON.stringify({
         customer: aGV.customer, //"cust01",
         from: "noreply@ellegm.it", //),trovaCost("MAILFROM") 
         to: toox, //"alberto.cappelli@libero.it", // "alberto.cappelli@libero.it",   
         cc: cccx,
         sub: subx,   
         body: bodx, 
         sendType: tipx
      });
      var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
       };
       var url = aGV.urlp + "InvioMail/InvioFast" //   : https://www.ellegm.it/AMG_Portale/Amg/ws/PIP_Ws/"
       //console.log(url)
       //console.log(raw)
       //return
       fetch(url, requestOptions) 
         .then((response) => response.json())
         .then((datiricevuti) => {
          //console.log(datiricevuti)
          //  console.log("datiricevuti"    )
          if (datiricevuti.status == "INVIATA") {
            calx()
            return
          } else {
             //console.log(datiricevuti.error);
             alert(datiricevuti.error)
          }
      })
       .catch( err =>  {  
         alert(err.message)
      })
}

export function replApice(strx)  {  //strx sostituisce apice
   var cStrx=strx
   if (cStrx==='')  { 
      return cStrx
   }else { 
      cStrx=cStrx.replaceAll("'","''") 
      return cStrx
   }
}

export function lenReducer(strx,lenx,carx)  {  //strx da ridurre lenx=lunghezza carx=cosa mettere in fondo 
    var cStrx=strx
    if (cStrx.length<lenx)  { 
       return cStrx
    }else { 
       cStrx=cStrx.substr(0,lenx)+carx 
       return cStrx
    }
}
export function convBlankTrat(strx)  { 
    var cStrx=strx
    if (strx==='')  { 
       return "---"
    }else { 
       return cStrx
    }
   
 }
 export function convBlankPunt(strx)  { 
    var cStrx=strx
    if (strx==='')  { 
       return "..."
    }else { 
       return cStrx
    }
   
 }
 export function convPuntBlank(strx)  { 
    var cStrx=strx
    if (strx==='...')  { 
       return ""
    }else { 
       return cStrx
    }
   
 }
export function notifyToast(tesx) {
    toast(tesx);
}

export function convErrorMessage(tesx) { 
    //converte testo es Roles - *** Ruolo già esistente
    if (tesx===''){
        return ''
    } else { 
         var n=tesx.indexOf("***")
         return tesx.substr(n+4)
    }      
    
}
export function convDataMGA(datx,sepx)  {  //converte da gg/mm/aaaa in mm/gg/aaaa
    var c=""
    if (datx!=='')   {  
        c=datx.substr(3,2)+sepx+ datx.substr(0,2)+sepx+datx.substr(6,4)
    }else    {  
        c=datx
    }
    return c
  
}    
export function convDatyMGA(datx,sepx)  {  //converte da aaaa/mm/gg in mm/gg/aaaa
   var c=""
   if (datx!=='')   {  
       c=datx.substr(5,2)+sepx+ datx.substr(8,2)+sepx+datx.substr(0,4)
   }else    {  
       c=datx
   }
   return c
 
}    
export function convDatyGMA(datx,sepx)  {  //converte da aaaa/mm/gg in gg/mm/aaaa
   var c=""
   if (datx!=='')   {  
       c=datx.substr(8,2)+sepx+ datx.substr(5,2)+sepx+datx.substr(0,4)
   }else    {  
       c=datx
   }
   return c
 
}    
export function convDataAMG(datx,sepx)  {  //converte da gg/mm/aaaa in aaaa-mm-gg usando sepx
    var c=""
    if (datx!=='')   {  
         c=datx.substr(6,4)+sepx+ datx.substr(3,2)+sepx+datx.substr(0,2)
    }else    {  
         c=datx
    }
    return c
}    
export function convDataAMGH(datx,sepx)  {  //converte da gg/mm/aaaa hh:mm:ss in aaaa-mm-gg hh:mm:ss
    var c=""
    if (datx!=='')    {  
           c=datx.substr(6,4)+sepx+ datx.substr(3,2)+sepx+datx.substr(0,2)+sepx+ datx.substr(11,2)
           c=c+':'+datx.substr(14,2)+':'  +datx.substr(17,2)
    }else    {  
           c=datx
    }
    return c
}    

