import './../App.css';
import React, { useState ,useEffect,useContext} from 'react';
import HomeSide from './HomeSide.js';
import { Card,Button,ButtonGroup} from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useNavigate } from "react-router-dom";
import {useLocation} from 'react-router-dom';
import {notifyToast,convErrorMessage} from './Functions.js';
import { ToastContainer } from 'react-toastify';
import { GlobalVariables,convPuntBlank,replApice } from './Functions.js';
import ShowModal from './ShowAlert.js'; 
import ShowModalConfirm from './ShowConfirm.js'; 

const RuolEdit = ()  => { //in uselocation.state ho opzi=I U D  indi=ndice  data=record corrente
     //variabili globali
     const aGV= useContext(GlobalVariables)

     var options=[{value: 'select'}, ,{value: 'other'},{value: 'nuovo'}]
     //parametri di chiamata  I U D
     const location = useLocation();
     const datx=location.state.data
     //cCurrOpzi lo cambio se elimino
     const [cCurrOpzi, setCurrOpzi] = useState(location.state.opzi);
     //var cCurrOpzi=location.state.opzi
     var cCurrOpziTito="Ruolo: "+datx.CODX
     if (cCurrOpzi==='I'){
       cCurrOpziTito="Nuovo ruolo"
       datx.SOCI=""
       datx.CODX=""
       datx.DESX=""
       datx.RIF1=""
       datx.RIF2=""
       datx.RIF3=""
     }

     //funzioni sele
     const aSeleSoci=aGV.aConfSoci
     
     //parametri router
     const navigate = useNavigate(); 
     
     //parametri modal 
     const [openModal, setOpenModal] = useState(false);
     const onOpenModal = () => setOpenModal(true);
     const onCloseModal = () => setOpenModal(false);
    
    
    
     //parametri modal confirm
     const [openModalConfirm, setOpenModalConfirm] = useState(false);
     const onOpenModalConfirm = () => setOpenModalConfirm(true);
     const onCloseModalConfirm = () => setOpenModalConfirm(false);
     const onReturnModalConfirm = () => 
     { //conferma alla eliminazione
          setOpenModalConfirm(false);
          console.log(cCurrOpzi)
          saveRuol() 
     }  

     //select 
 

     //variabili dell'oggetto
     //se nuo metto blank altrimento assegno
     const [txtRuolEditSoci, setRuolEditSoci] = useState(datx.SOCI);
     const [txtRuolEditCodx, setRuolEditCodx] = useState(datx.CODX);
     const [txtRuolEditDesx, setRuolEditDesx] = useState(datx.DESX);
     const [txtRuolEditRif1, setRuolEditRif1] = useState(datx.RIF1);
     const [txtRuolEditRif2, setRuolEditRif2] = useState(datx.RIF2);
     const [txtRuolEditRif3, setRuolEditRif3] = useState(datx.RIF3);
     const [titoModal, setTitoModal] = useState("");
     const [bodyModal, setBodyModal] = useState("");
     const [buttModal, setButtModalConfirm] = useState("Conferma");
     
     //riempio la select
     
    
     //funzioni routing
     function handleBtnRuolEditAggiClick() {  //funzione Aggiorna
         checkRuol()
     }
     function handleBtnRuolEditChiuClick() {  //funzione chiudi
          navigate("/Ruol");
     }
     function handleBtnRuolEditElimClick() {  //funzione elimina
          setTitoModal("Attenzione")
          setBodyModal("Conferma eliminazione ruolo: "+txtRuolEditCodx+"?");
          setCurrOpzi("D")
          //alert("PPPP")
          onOpenModalConfirm()
      }

    
               //esempi di fine salvataggio ma poi li togliamo  
               //setTitoModal("Info")
               //setBodyModal("Dati archiviati con successo".)
               //onOpenModal()
               //setOpenModal(true);  //equivalente a sopra openmodal
                    
    
     //funzioni check save
     function checkRuol() {   //controllo dati
         var cErro=""
         var cLabe=""
         //var c="pippo d'antoni"
         //alert(replApice(c))
         //return
         if (txtRuolEditSoci.trim()===''){
               cErro=cErro+("Digitare società **")
               cLabe=cLabe+"#lblConfRuolEditSoci|" //per ora non usata 
         }
         if (txtRuolEditCodx.trim()===''){
               cErro=cErro+("Digitare codice **")
               cLabe=cLabe+"#lblConfRuolEditCodx|"
         }
         if (txtRuolEditDesx.trim()===''){
               cErro=cErro+("Digitare descrizione **")
               cLabe=cLabe+"#lblConfRuolEditDesx|" 
         }
         if (cErro===''){
               saveRuol()
         } else {
               notifyToast(cErro)  
         }
   
     }
     function saveRuol() { //salvataggio
          //alert(cCurrOpzi)
          //return
          var cData=""
          var cKeyc=""
          if (cCurrOpzi==='I'){ //insert
                cKeyc= "company;code|'"+txtRuolEditSoci+"';'"+txtRuolEditCodx+"'"
                cData= "company;code;description;rif1;rif2;rif3|"
                cData=cData +"'" +txtRuolEditSoci+"';"
                cData=cData +"'" +txtRuolEditCodx+"';"
                cData=cData +"'" +replApice(txtRuolEditDesx)+"';"
                cData=cData +"'" +txtRuolEditRif1+"';"
                cData=cData +"'" +txtRuolEditRif2+"';"
                cData=cData +"'" +txtRuolEditRif3+"'"
          }      
          if (cCurrOpzi==='U'){ //update
                cKeyc= "company;code|'"+txtRuolEditSoci+"';'"+txtRuolEditCodx+"'"
                cData= "description;rif1;rif2;rif3|"
                cData=cData +"'" +replApice(txtRuolEditDesx)+"';"
                cData=cData +"'" +txtRuolEditRif1+"';"
                cData=cData +"'" +txtRuolEditRif2+"';"
                cData=cData +"'" +txtRuolEditRif3+"'"
          }  
          if (cCurrOpzi==='D')  { //elimina
                cKeyc= "company;code|'"+txtRuolEditSoci+"';'"+txtRuolEditCodx+"'"
                cData= "|"
          }
          //console.log(cData)    
          //return
          var myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/json");
          var raw = JSON.stringify({
             customer: aGV.customer, //"AMGDEMO", //"cust01",
             user: aGV.user, //"alberto.cappelli@libero.it", //"pippo",
             type: cCurrOpzi,    // UDI
             keyc: cKeyc,
             data: cData,
          });
          var requestOptions = {
             method: "POST",
             headers: myHeaders,
             body: raw,
             redirect: "follow",
          };
          const cUrl=aGV.urll+"AMG_Roles" //"https://amg.datapartners.ch/Amg/ws/AMG_WS/AMG_Roles"
          
          // console.log(cUrl)
          //console.log(raw)
          //return
          //console.log(requestOptions)
             
          fetch(cUrl,requestOptions)
               //console.log(data)
               .then(response =>  { return response.json()  }) 
               .then( datiricevuti => { 
                    //in datiricevuti.error manda  errori es codice esistente
                    if (datiricevuti.error!=='') { 
                         //console.log(datiricevuti.error)
                         setTitoModal("Attenzione")
                         setBodyModal(convErrorMessage(datiricevuti.error))
                         //alert("PPPP")
                         onOpenModal()
                    } else { 
                          handleBtnRuolEditChiuClick()
                    }      
               })
               .catch( err =>  {  
                    //console.log(err)
                    setTitoModal("Attenzione")
                    setBodyModal(err.message)
                    //alert("PPPP")
                    onOpenModal()
               })
          }   
  return (    
    <div style={window.$stiTop} > 
        <div style={window.$stiLeft}>
            <HomeSide></HomeSide>
        </div> 
        <div style={window.$stiRight} >
           <h2  >AMG Foundation Ruoli </h2>
           <hr />
           <h4 >{cCurrOpziTito}</h4>
          
           <div className="divDummyAlign">
           <ButtonGroup   aria-label="Button Group" className="btnGroup">
               <Button variant="danger" size="sm"  onClick={handleBtnRuolEditAggiClick}>Aggiorna</Button>    
               <Button variant="danger" size="sm"  onClick={handleBtnRuolEditElimClick}
                hidden={cCurrOpzi==='U' ? false : true}
               >Elimina</Button>              
               <Button variant="secondary" size="sm"  onClick={handleBtnRuolEditChiuClick}> Chiudi</Button>
           </ButtonGroup>
           </div>
           <pre></pre>
           <Card className="shadow p-3 mb-5 bg-white rounded">
           <Row >
           <Col className="col-md-4" >      
              <Card.Header className="card-header" >Dati base</Card.Header>  
              <Card.Body className="card-body">  
                 <Form.Group className="form-floating mb-3" >
                      <Form.Select
                         disabled={cCurrOpzi==='U' ? true : false}
                         defaultValue={txtRuolEditSoci}
                         onChange={(event) => setRuolEditSoci(event.target.value)}
                      >
                         <option>...</option>
                         {  aSeleSoci.map((option, index) => {
                         return (<option key={option.id} value={option.CODX}>{option.DESX}</option>)
                         })  }
                      </Form.Select>
                      <Form.Label >Società</Form.Label>
                 </Form.Group>  
                 <Form.Group className="form-floating mb-3" >
                      <Form.Control type="text"  value={txtRuolEditCodx}
                      disabled={cCurrOpzi==='U' ? true : false}
                      onChange={(event)=> setRuolEditCodx(event.target.value)}/>
                      <Form.Label >Codice</Form.Label>
                 </Form.Group>  
               
                 <Form.Group className="form-floating mb-3" >
                      <Form.Control type="text" value={txtRuolEditDesx}
                       onChange={(event)=> setRuolEditDesx(event.target.value)}/>
                      <Form.Label >Descrizione</Form.Label>
                 </Form.Group>  
               
              </Card.Body> 
            </Col>            
            <Col className="col-md-4" >      
              <Card.Header className="card-header" >Dati addizionali</Card.Header>  
              <Card.Body className="card-body">  
                 <Form.Group className="form-floating mb-3" >
                      <Form.Control type="text" id="txtRuolEditRif1" value={txtRuolEditRif1}
                      onChange={(event)=> setRuolEditRif1(event.target.value)}/>
                      <Form.Label htmlFor="txtRuolEditRif1" id="lblRuolEditRif1">Add.1</Form.Label>
                 </Form.Group>  
                 <Form.Group className="form-floating mb-3" >
                      <Form.Control type="text" id="txtRuolEditRif2" value={txtRuolEditRif2}
                      onChange={(event)=> setRuolEditRif2(event.target.value)}/>
                      <Form.Label htmlFor="txtRuolEditRif2" id="lblRuolEditRif2">Add.2</Form.Label>
                 </Form.Group>  
                 <Form.Group className="form-floating mb-3" >
                      <Form.Control type="text" id="txtRuolEditRif3" value={txtRuolEditRif3}
                      onChange={(event)=> setRuolEditRif3(event.target.value)}/>
                      <Form.Label htmlFor="txtRuolEditRif3" id="lblRuolEditRif3">Add.3</Form.Label>
                 </Form.Group>  
               
              </Card.Body> 
            </Col>            
           
        
           </Row>      
           <ToastContainer />
          
    
           </Card> 
           { openModal &&
                    <ShowModal open={openModal} onClose={onCloseModal} 
                    tito={titoModal} text={bodyModal} 
                    ></ShowModal>
           }   
           { openModalConfirm &&
                    <ShowModalConfirm open={openModalConfirm} onClose={onCloseModalConfirm} 
                    onReturn={onReturnModalConfirm}   
                    tito={titoModal} text={bodyModal} butt={buttModal} 
                    ></ShowModalConfirm>
           }              

         
     </div>     
   

</div>  

  )  
             
}              

    
 

export default RuolEdit;