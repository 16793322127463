
import './../App.css';
import HomeSide from './HomeSide.js';
import React from "react";




 


const Home = props => {
  //console.log(window.$stiTop)
  return (    
    <div style={window.$stiTop}  > 
       <div style={window.$stiLeft} >
          <HomeSide></HomeSide>
       </div> 
       <div style={window.$stiRight} >
        <h2  id="lbzAppTito">AMG Foundation Home Page</h2>
          <hr id="hrAppTito" />
          <p>At Datapartners we're proud to lead with our API-Centric SaaS model, 
          tailor-made for today's dynamic digital environment. Our approach leverages 
          the power of APIs  to integrate and communicate across diverse software services, 
          ensuring flexibility and scalability in our solutions.
          Central to our innovative framework are microservices: independent, agile, 
          and focused units that collectively create robust applications. 
          This structure allows for rapid adaptation to changing market demands and 
          customer needs, giving your business a competitive edge.
          Our microservices architecture offers unparalleled scalability, making it 
          easy to manage varying workloads and growth without compromising performance.
          It's a system designed for your business's expansion, adapting effortlessly 
          to your evolving requirements.Seamless integration is at the heart of our services. 
          With our APIs, your business can effortlessly connect with a range of services 
          and external applications, extending your capabilities and fostering innovation.
          We prioritize resilience. By isolating faults to individual services, 
          we ensure overall system stability and quick recovery, keeping your operations 
          smooth and uninterrupted. For developers, our model accelerates delivery, 
          facilitates continuous deployment, and supports diverse technologies for each 
          specific service. This flexibility hastens development and brings your products 
          to market faster. Security is integral to our design. Each service is secured 
          independently, offering enhanced protection and peace of mind.
          Choose Datapartners AMG for a SaaS solution that promises not just technology, 
          but transformation.We're here to redefine how your business meets the future, 
          with a commitment to innovation, efficiency, and unwavering security.</p>
        </div> 
     </div> 


    

 

  )  
             
}              

    
 

export default Home;