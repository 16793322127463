import './../App.css';
import React, { useState ,useEffect,useContext} from 'react';
import HomeSide from './HomeSide.js';
import { Card,Button,ButtonGroup} from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useNavigate } from "react-router-dom";
import {useLocation} from 'react-router-dom';
import {notifyToast,convErrorMessage} from './Functions.js';
import { ToastContainer } from 'react-toastify';
import { GlobalVariables,convPuntBlank,replApice } from './Functions.js';
import ShowModal from './ShowAlert.js'; 
import ShowModalConfirm from './ShowConfirm.js'; 


const CostEdit = ()  => { //in uselocation.state ho opzi=I U D  indi=ndice  data=record corrente
     //variabili globali
     const aGV= useContext(GlobalVariables)

     //parametri di chiamata  I U D
     const location = useLocation();
     const datx=location.state.data
     
     //cCurrOpzi lo cambio se elimino
     const [cCurrOpzi, setCurrOpzi] = useState(location.state.opzi);
     
     var cCurrOpziTito="Dato: "+datx.CODX
     if (cCurrOpzi==='I'){
       cCurrOpziTito="Nuovo dato"
       datx.SOCI=""
       datx.CODX=""
       datx.DESX=""
       datx.VALO=""
      
      
     }
     //parametri router
     const navigate = useNavigate(); 
     
     //parametri modal 
     const [openModal, setOpenModal] = useState(false);
     const onOpenModal = () => setOpenModal(true);
     const onCloseModal = () => setOpenModal(false);
    
     //parametri modal confirm
     const [openModalConfirm, setOpenModalConfirm] = useState(false);
     const onOpenModalConfirm = () => setOpenModalConfirm(true);
     const onCloseModalConfirm = () => setOpenModalConfirm(false);
     const onReturnModalConfirm = () => 
     { //conferma alla eliminazione
          setOpenModalConfirm(false);
          //console.log(cCurrOpzi)
          saveCost() 
     }  
     //variabili dell'oggetto
     //se nuovo metto blank altrimento assegno
     const [txtCostEditSoci, setCostEditSoci] = useState(datx.SOCI);
     const [txtCostEditCodx, setCostEditCodx] = useState(datx.CODX);
     const [txtCostEditDesx, setCostEditDesx] = useState(datx.DESX);
     const [txtCostEditValo, setCostEditValo] = useState(datx.VALO);
     const [titoModal, setTitoModal] = useState("");
     const [bodyModal, setBodyModal] = useState("");
     const [buttModal, setButtModalConfirm] = useState("Conferma");
    
     //funzioni sele
     const aSeleSoci=aGV.aConfSoci

     //funzioni routing
     function handleBtnCostEditAggiClick() {  //funzione Aggiorna
         checkCost()
     }
     function handleBtnCostEditChiuClick() {  //funzione chiudi
         navigate("/Cost");
     }
     function handleBtnCostEditElimClick() {  //funzione elimina
          setTitoModal("Attenzione")
          setBodyModal("Conferma eliminazione dato base "+txtCostEditCodx+"?");
          setCurrOpzi("D")
          //alert("PPPP")
          onOpenModalConfirm()
     }
 
     //funzioni check save
     function checkCost() {   //controllo dati
         var cErro=""
         var cLabe=""
         if (txtCostEditSoci.trim()===''){
             cErro=cErro+("Selezionare società **")
             cLabe=cLabe+"#lblConfCostEditSoci|"
    }
         if (txtCostEditCodx.trim()===''){
               cErro=cErro+("Digitare codice **")
               cLabe=cLabe+"#lblConfCostEditCodx|"
         }
         if (txtCostEditDesx.trim()===''){
               cErro=cErro+("Digitare descrizione **")
               cLabe=cLabe+"#lblConfCostEditDesx|" 
         }
         
         if (cErro===''){
               saveCost()
         } else {
               notifyToast(cErro)  
         }
   
     }
     function saveCost() { //salvataggio
          //alert(cCurrOpzi)
          //return
          var cData=""
          var cKeyc=""
          if (cCurrOpzi==='I'){ //insert
                cKeyc= "company;code|'"+txtCostEditSoci+"';'"+txtCostEditCodx+"'"
                cData= "company;code;description;itemValue|"
                cData=cData +"'" +txtCostEditSoci+"';"
                cData=cData +"'" +txtCostEditCodx+"';"
                cData=cData +"'" +replApice(txtCostEditDesx)+"';"
                cData=cData +"'" +replApice(txtCostEditValo)+"';"
               
          }      
          if (cCurrOpzi==='U'){ //update
                cKeyc= "company;code|'"+txtCostEditSoci+"';'"+txtCostEditCodx+"'"
                cData= "description;itemValue|"
                cData=cData +"'" +replApice(txtCostEditDesx)+"';"
                cData=cData +"'" +replApice(txtCostEditValo)+"';"
          }  
          if (cCurrOpzi==='D')  { //elimina
                cKeyc= "company;code|'"+txtCostEditSoci+"';'"+txtCostEditCodx+"'"
                cData= "|"
          }
         
          var myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/json");
          var raw = JSON.stringify({
             customer: aGV.customer, //"AMGDEMO", //"cust01",
             user: aGV.user, //"alberto.cappelli@libero.it", //"pippo",
             type: cCurrOpzi,    // UDI
             keyc: cKeyc,
             data: cData,
          });
          var requestOptions = {
             method: "POST",
             headers: myHeaders,
             body: raw,
             redirect: "follow",
          };
          const cUrl=aGV.urll+"AMG_BaseData" //"https://amg.datapartners.ch/Amg/ws/AMG_WS/AMG_Roles"
          fetch(cUrl,requestOptions)
               //console.log(data)
               .then(response =>  { return response.json()  }) 
               .then( datiricevuti => { 
                    //in datiricevuti.error manda  errori es codice esistente
                    if (datiricevuti.error!=='') { 
                         //console.log(datiricevuti.error)
                         setTitoModal("Attenzione")
                         setBodyModal(convErrorMessage(datiricevuti.error))
                         onOpenModal()
                    } else { 
                          handleBtnCostEditChiuClick()
                    }      
               })
               .catch( err =>  {  
                    //console.log(err)
                    setTitoModal("Attenzione")
                    setBodyModal(err.message)
                    onOpenModal()
               })
          }   
  return (    
    <div style={window.$stiTop} > 
        <div style={window.$stiLeft}>
            <HomeSide></HomeSide>
        </div> 
        <div style={window.$stiRight} >
           <h2  >AMG Foundation Dati base</h2>
           <hr />
           <h4 >{cCurrOpziTito}</h4>
           <div className="divDummyAlign">
             <ButtonGroup   aria-label="Button Group" className="btnGroup">
               <Button variant="danger" size="sm"  onClick={handleBtnCostEditAggiClick}>Aggiorna</Button>    
               <Button variant="danger" size="sm"  onClick={handleBtnCostEditElimClick}
                hidden={cCurrOpzi==='U' ? false : true}
                >Elimina</Button>              
               <Button variant="secondary" size="sm"  onClick={handleBtnCostEditChiuClick}> Chiudi</Button>
             </ButtonGroup>
           </div>
           <pre></pre>
           <Card className="shadow p-3 mb-5 bg-white rounded">
             <Row >
             <Col className="col-md-4" >      
              <Card.Header className="card-header" >Dati base</Card.Header>  
              <Card.Body className="card-body">  
                 <Form.Group className="form-floating mb-3" >
                     <Form.Select
                         disabled={cCurrOpzi==='U' ? true : false}
                         defaultValue={txtCostEditSoci}
                         onChange={(event) => setCostEditSoci(event.target.value)}
                     >
                         <option>...</option>
                         {  aSeleSoci.map((option, index) => {
                         return (<option key={option.id} value={option.CODX}>{option.DESX}</option>)
                         })  }
                     </Form.Select>
                     <Form.Label >Società</Form.Label>
                 </Form.Group>
                <Form.Group className="form-floating mb-3" >
                      <Form.Control type="text" value={txtCostEditCodx}
                      disabled={cCurrOpzi==='U' ? true : false}
                      onChange={(event)=> setCostEditCodx(event.target.value)}/>
                      <Form.Label >Codice</Form.Label>
                 </Form.Group>  
                
                 <Form.Group className="form-floating mb-3" >
                      <Form.Control type="text" value={txtCostEditDesx}
                       onChange={(event)=> setCostEditDesx(event.target.value)}/>
                      <Form.Label >Descrizione</Form.Label>
                 </Form.Group>  
               </Card.Body> 
            </Col>    
            <Col className="col-md-4" >      
              <Card.Header className="card-header" >Valore</Card.Header>  
              <Card.Body className="card-body">  
                 <Form.Group className="form-floating mb-3" >
                 <Form.Control as="textarea" className="form-control-textareaLg" value={txtCostEditValo}
                       onChange={(event)=> setCostEditValo(event.target.value)}/>
                      <Form.Label >Valore</Form.Label>
                 </Form.Group>  
               </Card.Body> 
            </Col>    
         
           
        
           </Row>      
           <ToastContainer />
          
    
           </Card> 
           { openModal &&
                    <ShowModal open={openModal} onClose={onCloseModal} 
                    tito={titoModal} text={bodyModal} 
                    ></ShowModal>
           }   
           { openModalConfirm &&
                    <ShowModalConfirm open={openModalConfirm} onClose={onCloseModalConfirm} 
                    onReturn={onReturnModalConfirm}   
                    tito={titoModal} text={bodyModal} butt={buttModal} 
                    ></ShowModalConfirm>
           }              
     </div>   
   </div>  //div globale 
  ) //return  
             
}  //componente             

export default CostEdit;