import './../App.css';
import React, { useState ,useEffect,useContext} from 'react';
import HomeSide from './HomeSide.js';
import { Card,Button,ButtonGroup} from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useNavigate } from "react-router-dom";
import {useLocation} from 'react-router-dom';
import {notifyToast,convErrorMessage} from './Functions.js';
import { ToastContainer } from 'react-toastify';
import { GlobalVariables,convPuntBlank,replApice,chiamaInvioFast } from './Functions.js';
import ShowModal from './ShowAlert.js'; 
import ShowModalConfirm from './ShowConfirm.js'; 
import ShowLook from './ShowLook.js'; 
import { FaRainbow } from 'react-icons/fa';



const UtenEdit = ()  => { //in uselocation.state ho opzi=I U D  indi=ndice  data=record corrente
     //variabili globali
     const aGV= useContext(GlobalVariables)

     //parametri di chiamata  I U D
     const location = useLocation();
     const datx=location.state.data
     
     //cCurrOpzi lo cambio se elimino
     const [cCurrOpzi, setCurrOpzi] = useState(location.state.opzi);
     //cResePrev lo settiamo cosi mandiamo solo la dataprivacy
     //const [cCurrResePriv, setCurrResePriv] = useState("");
     //console.log(datx)
     var cCurrResePriv=""
     var cCurrOpziTito="Utente: "+datx.EMAI
     if (cCurrOpzi==='I'){
       cCurrOpziTito="Nuovo utente"
       datx.EMAI=""
       datx.DESX=""
       datx.NICK=""
       datx.SOCI=""
       datx.SEDI=""
       datx.GRUP=""
       datx.RUOL=""
       datx.UTEC=""
       datx.DATC=""
       datx.TELE=""
       datx.TEL2=""
       datx.CELL=""
       datx.AUT2=""
       datx.RIF1=""
       datx.RIF2=""
       datx.RIF3=""
       datx.PRID=""
       datx.DARL=""
       datx.THEM=""
       datx.NOTI=""
       datx.CARD=""
            
     }
     //parametri router
     const navigate = useNavigate(); 
     
     //parametri modal 
     const [openModal, setOpenModal] = useState(false);
     const onOpenModal = () => setOpenModal(true);
     const onCloseModal = () => setOpenModal(false);
     const [titoModal, setTitoModal] = useState("");
     const [bodyModal, setBodyModal] = useState("");
     const [buttModal, setButtModalConfirm] = useState("Conferma");
    

      //parametri look
      const [openLook, setOpenLook] = useState(false);
      const onOpenLook = () => setOpenLook(true);
      const onCloseLook = () => setOpenLook(false);
      const [titoLook, setTitoLook] = useState("");
      const [stepLook, setStepLook] = useState("");
      const [attxLook, setAttxLook] = useState("");
      
    
     //parametri modal confirm
     const [openModalConfirm, setOpenModalConfirm] = useState(false);
     const onOpenModalConfirm = () => setOpenModalConfirm(true);
     const onCloseModalConfirm = () => setOpenModalConfirm(false);
     const onReturnModalConfirm = () => 
     { //conferma alla eliminazione
          setOpenModalConfirm(false);
          //console.log(cCurrOpzi)
          saveUten() 
     }  
     //variabili dell'oggetto
     //se nuovo metto blank altrimento assegno
     const [txtUtenEditEmai, setUtenEditEmai] = useState(datx.EMAI);
     const [txtUtenEditDesx, setUtenEditDesx] = useState(datx.DESX);
     const [txtUtenEditNick, setUtenEditNick] = useState(datx.NICK);
     const [txtUtenEditLang, setUtenEditLang] = useState(datx.LANG);
     const [txtUtenEditSoci, setUtenEditSoci] = useState(datx.SOCI);
     const [txtUtenEditSedi, setUtenEditSedi] = useState(datx.SEDI);
     const [txtUtenEditGrup, setUtenEditGrup] = useState(datx.GRUP);
     const [txtUtenEditRuol, setUtenEditRuol] = useState(datx.RUOL);
     const [txtUtenEditUtec, setUtenEditUtec] = useState(datx.UTEC);
     const [txtUtenEditDatc, setUtenEditDatc] = useState(datx.DATC);
     const [txtUtenEditTele, setUtenEditTele] = useState(datx.TELE);
     const [txtUtenEditTel2, setUtenEditTel2] = useState(datx.TEL2);
     const [txtUtenEditCell, setUtenEditCell] = useState(datx.CELL);
     const [txtUtenEditAut2, setUtenEditAut2] = useState(datx.AUT2);
     const [txtUtenEditRif1, setUtenEditRif1] = useState(datx.RIF1);
     const [txtUtenEditRif2, setUtenEditRif2] = useState(datx.RIF2);
     const [txtUtenEditRif3, setUtenEditRif3] = useState(datx.RIF3);
     const [txtUtenEditPrid, setUtenEditPrid] = useState(datx.PRID);
     const [txtUtenEditDarl, setUtenEditDarl] = useState(datx.DARL);
     const [txtUtenEditThem, setUtenEditThem] = useState(datx.THEM);
     const [txtUtenEditNoti, setUtenEditNoti] = useState(datx.NOTI);
     const [txtUtenEditCard, setUtenEditCard] = useState(datx.CARD);
   



     
     //funzioni sele
     const aSeleSoci=aGV.aConfSoci
     const aSeleSedi=aGV.aConfSedi
     const aSeleRuol=aGV.aConfRuol
     const aSeleAut2 = [ 
        { "id": "0","CODX": "OFF","DESX": "OFF"},
        { "id": "1","CODX": "MAIL","DESX": "MAIL"},
        { "id": "2","CODX": "SMS","DESX": "SMS"}
     ]
     const aSeleDarl = [ 
        { "id": "0","CODX": "Dark","DESX": "Dark"},
        { "id": "1","CODX": "Light","DESX": "Light"},
     ]
     const aSeleThem = [ 
        { "id": "0","CODX": "Red","DESX": "Red"},
        { "id": "1","CODX": "Green","DESX": "Green"},
        { "id": "2","CODX": "Blue","DESX": "Blue"}
     ]
     const aSeleNoti = [ 
        { "id": "0","CODX": "Y","DESX": "Y"},
        { "id": "1","CODX": "N","DESX": "N"}
       
     ]
     const aSeleLang = [ 
      { "id": "0","CODX": "IT","DESX": "IT"},
      { "id": "1","CODX": "EN","DESX": "EN"},
      { "id": "2","CODX": "FR","DESX": "FR"},
      { "id": "3","CODX": "ES","DESX": "ES"},
      { "id": "4","CODX": "DE","DESX": "DE"}
     
     
   ]




     //funzioni routing
     function handleBtnUtenEditRespClick() {  //funzione reset privacy devo salvare con data pèrivacy=bòank
          //setCurrResePriv("Y") 
          cCurrResePriv="Y"
          alert(cCurrResePriv)
          checkUten()    
          //alert("Reset privacy")
     }
     function handleBtnUtenEditViepClick() {  //funzione Aggiorna
        setTitoLook("Elenco dati privacy utente:"+" " +txtUtenEditEmai)
        setStepLook("UTENEDITPRIV")
        setAttxLook("log")
        onOpenLook()
      
     }
     function handleBtnUtenEditAggiClick() {  //funzione Aggiorna
         //setCurrResePriv("") 
         cCurrResePriv=""
         checkUten()
     }
     function handleBtnUtenEditChiuClick() {  //funzione chiudi
         navigate("/Uten");
     }
     function handleBtnUtenEditElimClick() {  //funzione elimina
          setTitoModal("Attenzione")
          setBodyModal("Conferma eliminazione utente "+txtUtenEditEmai+"?");
          setCurrOpzi("D")
          //alert("PPPP")
          onOpenModalConfirm()
     }
 
     //funzioni check save
     function checkUten() {   //controllo dati
         var cErro=""
         var cLabe=""
         if (txtUtenEditEmai.trim()===''){
            cErro=cErro+("Digitare Email **")
            cLabe=cLabe+"#lblConfUtenEditEmai|"
         }
         if (txtUtenEditDesx.trim()===''){
           cErro=cErro+("Digitare Nome **")
           cLabe=cLabe+"#lblConfUtenEditDesx|"
         }
         if (txtUtenEditNick.trim()===''){
           cErro=cErro+("Digitare Nick name **")
           cLabe=cLabe+"#lblConfUtenEditEmai|"
         }
         if (txtUtenEditLang.trim()===''){
          cErro=cErro+("Selezionare lingua **")
          cLabe=cLabe+"#lblConfUtenEditSoci|"
         }
         if (txtUtenEditSoci.trim()===''){
           cErro=cErro+("Selezionare società **")
           cLabe=cLabe+"#lblConfUtenEditSoci|"
         }
         if (txtUtenEditAut2.trim()===''){
          cErro=cErro+("Selezionare aut.multif. **")
          cLabe=cLabe+"#lblConfUtenEditSoci|"
         }
        
         if (cErro===''){
               saveUten()
         } else {
               notifyToast(cErro)  
         }
   
     }
     function saveUten() { //salvataggio
          //alert(cCurrOpzi)
          //return
          var cData=""
          var cKeyc=""
          if (cCurrOpzi==='I'){ //insert
                cKeyc= "email|'"+txtUtenEditEmai+"'"
                cData= "email;description;nickName;language;companies;branches;groups;roles;phone;phone2;phoneCell;auth2;rif1;rif2;rif3;"
                cData=cData+"DarkLight;Theme;Notification;Cards|"
                cData=cData +"'" +txtUtenEditEmai+"';"
                cData=cData +"'" +replApice(txtUtenEditDesx)+"';"
                cData=cData +"'" +replApice(txtUtenEditNick)+"';"
                cData=cData +"'" +txtUtenEditLang+"';"
                cData=cData +"'" +txtUtenEditSoci+"';"
                cData=cData +"'" +txtUtenEditSedi+"';"
                cData=cData +"'" +txtUtenEditGrup+"';"
                cData=cData +"'" +txtUtenEditRuol+"';"
                cData=cData +"'" +txtUtenEditTele+"';"
                cData=cData +"'" +txtUtenEditTel2+"';"
                cData=cData +"'" +txtUtenEditCell+"';"
                cData=cData +"'" +txtUtenEditAut2+"';"
                cData=cData +"'" +txtUtenEditRif1+"';"
                cData=cData +"'" +txtUtenEditRif2+"';"
                cData=cData +"'" +txtUtenEditRif3+"';"
                cData=cData +"'" +txtUtenEditDarl+"';"
                cData=cData +"'" +txtUtenEditThem+"';"
                cData=cData +"'" +txtUtenEditNoti+"';"
                cData=cData +"'" +txtUtenEditCard+"';"
               
              
               
          }      
          if (cCurrOpzi==='U'){ //update
              cKeyc= "email|'"+txtUtenEditEmai+"'"
              cData= "description;nickName;language;companies;branches;groups;roles;phone;phone2;phoneCell;"
              cData=cData+"auth2;rif1;rif2;rif3;"
              cData=cData+"DarkLight;Theme;Notification;Cards|"
              cData=cData +"'" +replApice(txtUtenEditDesx)+"';"
              cData=cData +"'" +replApice(txtUtenEditNick)+"';"
              cData=cData +"'" +txtUtenEditLang+"';"
              cData=cData +"'" +txtUtenEditSoci+"';"
              cData=cData +"'" +txtUtenEditSedi+"';"
              cData=cData +"'" +txtUtenEditGrup+"';"
              cData=cData +"'" +txtUtenEditRuol+"';"
              cData=cData +"'" +txtUtenEditTele+"';"
              cData=cData +"'" +txtUtenEditTel2+"';"
              cData=cData +"'" +txtUtenEditCell+"';"
              cData=cData +"'" +txtUtenEditAut2+"';"
              cData=cData +"'" +txtUtenEditRif1+"';"
              cData=cData +"'" +txtUtenEditRif2+"';"
              cData=cData +"'" +txtUtenEditRif3+"';"
              cData=cData +"'" +txtUtenEditDarl+"';"
              cData=cData +"'" +txtUtenEditThem+"';"
              cData=cData +"'" +txtUtenEditNoti+"';"
              cData=cData +"'" +txtUtenEditCard+"';"
             
          }  
          if (cCurrOpzi==='D')  { //elimina
               cKeyc= "email|'"+txtUtenEditEmai+"'"
               cData= "|"
          }
          if (cCurrResePriv==='Y'){ //reset privacy
            cKeyc= "email|'"+txtUtenEditEmai+"'"
            cData= "privacydate|"
            cData=cData +"'01/01/1900';"
          }  
          //console.log(cCurrResePriv)
          //return
          var myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/json");
          var raw = JSON.stringify({
             customer: aGV.customer, //"AMGDEMO", //"cust01",
             user: aGV.user, //"alberto.cappelli@libero.it", //"pippo",
             type: cCurrOpzi,    // UDI
             keyc: cKeyc,
             data: cData,
          });
          var requestOptions = {
             method: "POST",
             headers: myHeaders,
             body: raw,
             redirect: "follow",
          };
          //console.log(raw)
          //return
          const cUrl=aGV.urll+"AMG_Users" //"https://amg.datapartners.ch/Amg/ws/AMG_WS/AMG_Roles"
          fetch(cUrl,requestOptions)
               //console.log(data)
               .then(response =>  { return response.json()  }) 
               .then( datiricevuti => { 
                    //in datiricevuti.error manda  errori es codice esistente
                    if (datiricevuti.error!=='') { 
                         //console.log(datiricevuti.error)
                         setTitoModal("Attenzione")
                         setBodyModal(convErrorMessage(datiricevuti.error))
                         onOpenModal()
                    } else { 
                      if (cCurrOpzi==='I')  { //inserisc quindi deve fare altro
                         handleUtenEditPostInse(handleBtnUtenEditChiuClick)
                      } else {      
                         handleBtnUtenEditChiuClick()
                      }     
                    }      
               })
               .catch( err =>  {  
                    //console.log(err)
                    setTitoModal("Attenzione")
                    setBodyModal(err.message)
                    onOpenModal()
               })
     }  //fine function save uten 
     function handleUtenEditPostInse()  {  //funzione post save utente
          var myHeaders = new Headers(); 
          myHeaders.append("Content-Type", "application/json");
          var raw = JSON.stringify({
               customer: aGV.customer, //"AMGDEMO", //"cust01",
               user: txtUtenEditEmai //utente nuovo
          });
          var requestOptions = {
             method: "POST",
             headers: myHeaders,
             body: raw,
             redirect: "follow"
          };
          //console.log(raw)
          //return
          const cUrl=aGV.urls+"CreatePassword" 
          //console.log(cUrl)
          //console.log(raw)
          fetch(cUrl,requestOptions)
             
              .then(response =>  { return response.json()  }) 
              .then( datiricevuti => { 
                //in datiricevuti.error manda  errori es codice esistente
                //console.log(datiricevuti)
                if (datiricevuti.error!=='') { 
                     alert(convErrorMessage(datiricevuti.error))
                     return 
                } else { 
                     var cPass=datiricevuti.psw //password creata
                     if (cPass!='')  {
                        var cBody="La sua password di ingresso in AMG è la seguente:"+cPass
                        var cSubj="AMG Assegnazione Password"
                        //alert(cPass)
                        chiamaInvioFast(aGV,cSubj,cBody,"MAIL",txtUtenEditEmai,"",handleBtnUtenEditChiuClick)   //in functions
                        return
                     } else {    
                        alert(convErrorMessage("Errore invio password"))
                        return 
                     }        
                }      
           })
           .catch( err =>  {  
                alert(err.message)
           })
      }    


         
  return (    
    <div style={window.$stiTop} > 
        <div style={window.$stiLeft}>
            <HomeSide></HomeSide>
        </div> 
        <div style={window.$stiRight} >
           <h2  >AMG Foundation Utenti</h2>
           <hr />
           <h4 >{cCurrOpziTito}</h4> 
           <div className="divDummyAlign">
             <ButtonGroup   aria-label="Button Group" className="btnGroup">
               <Button variant="danger" size="sm"  onClick={handleBtnUtenEditAggiClick}>Aggiorna</Button>    
               <Button variant="danger" size="sm"  onClick={handleBtnUtenEditElimClick}
               hidden={cCurrOpzi==='U' ? false : true}
               >Elimina</Button>   
               <Button variant="danger" size="sm"  onClick={handleBtnUtenEditRespClick}>Reset privacy</Button>              
               <Button variant="danger" size="sm"  onClick={handleBtnUtenEditViepClick}>View privacy</Button>              
               <Button variant="secondary" size="sm"  onClick={handleBtnUtenEditChiuClick}> Chiudi</Button>
             
             </ButtonGroup>
            
           </div>
           <pre></pre>
           <Card className="shadow p-3 mb-5 bg-white rounded">
             <Row >
             <Col className="col-md-3" >      
              <Card.Header className="card-header" >Dati base</Card.Header>  
              <Card.Body className="card-body">  
                 <Form.Group className="form-floating mb-3" >
                      <Form.Control type="text" value={txtUtenEditEmai}
                      disabled={cCurrOpzi==='U' ? true : false}
                      onChange={(event)=> setUtenEditEmai(event.target.value)}/>
                      <Form.Label >Email</Form.Label>
                 </Form.Group>  
                 <Form.Group className="form-floating mb-3" >
                      <Form.Control type="text" value={txtUtenEditDesx}
                       onChange={(event)=> setUtenEditDesx(event.target.value)}/>
                      <Form.Label >Nome</Form.Label>
                 </Form.Group>  
                 <Form.Group className="form-floating mb-3" >
                      <Form.Control type="text" value={txtUtenEditNick}
                       onChange={(event)=> setUtenEditNick(event.target.value)}/>
                      <Form.Label >Nick name</Form.Label>
                 </Form.Group>  
                 <Form.Group className="form-floating mb-3" >
                     <Form.Select
                         defaultValue={txtUtenEditLang}
                         onChange={(event) => setUtenEditLang(event.target.value)}
                     >
                         <option>...</option>
                         {  aSeleLang.map((option, index) => {
                         return (<option key={option.id} value={option.CODX}>{option.DESX}</option>)
                         })  }
                     </Form.Select>
                     <Form.Label >Lingua</Form.Label>
                 </Form.Group>
                 <Form.Group className="form-floating mb-3" >
                      <Form.Control type="text" value={txtUtenEditUtec}
                      disabled={true}
                      onChange={(event)=> setUtenEditUtec(event.target.value)}/>
                      <Form.Label >Utente creazione</Form.Label>
                 </Form.Group>  
                 <Form.Group className="form-floating mb-3" >
                      <Form.Control type="text" value={txtUtenEditDatc}
                      disabled={true}
                      onChange={(event)=> setUtenEditDatc(event.target.value)}/>
                      <Form.Label >Data creazione</Form.Label>
                 </Form.Group>  
                 <Form.Group className="form-floating mb-3" >
                      <Form.Control type="text" value={txtUtenEditPrid}
                      disabled={true}
                      onChange={(event)=> setUtenEditPrid(event.target.value)}/>
                      <Form.Label >Data acc.privacy </Form.Label>
                 </Form.Group>  
                
               </Card.Body> 
            </Col>    
            <Col className="col-md-3" >      
              <Card.Header className="card-header" >Altri dati</Card.Header>  
              <Card.Body className="card-body">  
                <Form.Group className="form-floating mb-3" >
                     <Form.Select
                         defaultValue={txtUtenEditRuol}
                         onChange={(event) => setUtenEditRuol(event.target.value)}
                     >
                         <option>...</option>
                         {  aSeleRuol.map((option, index) => {
                         return (<option key={option.id} value={option.CODX}>{option.DESX}</option>)
                         })  }
                     </Form.Select>
                     <Form.Label >Ruolo</Form.Label>
                 </Form.Group>
                 <Form.Group className="form-floating mb-3" >
                     <Form.Select
                         defaultValue={txtUtenEditGrup}
                         onChange={(event) => setUtenEditGrup(event.target.value)}
                     >
                         <option>...</option>
                         {  aSeleRuol.map((option, index) => {
                         return (<option key={option.id} value={option.CODX}>{option.DESX}</option>)
                         })  }
                     </Form.Select>
                     <Form.Label >Gruppo</Form.Label>
                 </Form.Group>
                 <Form.Group className="form-floating mb-3" >
                     <Form.Select
                         defaultValue={txtUtenEditSoci}
                         onChange={(event) => setUtenEditSoci(event.target.value)}
                     >
                         <option>...</option>
                         {  aSeleSoci.map((option, index) => {
                         return (<option key={option.id} value={option.CODX}>{option.DESX}</option>)
                         })  }
                     </Form.Select>
                     <Form.Label >Società</Form.Label>
                 </Form.Group>
                 <Form.Group className="form-floating mb-3" >
                     <Form.Select
                         defaultValue={txtUtenEditSedi}
                         onChange={(event) => setUtenEditSedi(event.target.value)}
                     >
                         <option>...</option>
                         {  aSeleSedi.map((option, index) => {
                         return (<option key={option.id} value={option.CODX}>{option.DESX}</option>)
                         })  }
                     </Form.Select>
                     <Form.Label >Sede</Form.Label>
                 </Form.Group>
                 <Form.Group className="form-floating mb-3" >
                     <Form.Select
                         defaultValue={txtUtenEditAut2}
                         onChange={(event) => setUtenEditAut2(event.target.value)}
                     >
                         <option>...</option>
                         {  aSeleAut2.map((option, index) => {
                         return (<option key={option.id} value={option.CODX}>{option.DESX}</option>)
                         })  }
                     </Form.Select>
                     <Form.Label >Tipo autenticazione</Form.Label>
                 </Form.Group>
                 <Form.Group className="form-floating mb-3" >
                      <Form.Control as="textarea" className="form-control-textarea" value={txtUtenEditCard}
                       onChange={(event)=> setUtenEditCard(event.target.value)}/>
                      <Form.Label >Cards (separate da , con , in fondo))</Form.Label>
                 </Form.Group>  
               
               
              </Card.Body> 
            </Col>            
            <Col className="col-md-3" >      
              <Card.Header className="card-header" >Dati addizionali</Card.Header>  
              <Card.Body className="card-body">  
                 <Form.Group className="form-floating mb-3" >
                      <Form.Control type="text"  value={txtUtenEditTele}
                      onChange={(event)=> setUtenEditTele(event.target.value)}/>
                      <Form.Label >Telefono</Form.Label>
                 </Form.Group> 
                 <Form.Group className="form-floating mb-3" >
                      <Form.Control type="text"  value={txtUtenEditTel2}
                      onChange={(event)=> setUtenEditTel2(event.target.value)}/>
                      <Form.Label >Telefono2</Form.Label>
                 </Form.Group>  
                 <Form.Group className="form-floating mb-3" >
                      <Form.Control type="text"  value={txtUtenEditCell}
                      onChange={(event)=> setUtenEditCell(event.target.value)}/>
                      <Form.Label >Tel.cellulare</Form.Label>
                 </Form.Group>  
                
                 
                 <Form.Group className="form-floating mb-3" >
                      <Form.Control type="text"  value={txtUtenEditRif1}
                      onChange={(event)=> setUtenEditRif1(event.target.value)}/>
                      <Form.Label >Add.1</Form.Label>
                 </Form.Group>  
                 <Form.Group className="form-floating mb-3" >
                      <Form.Control type="text" value={txtUtenEditRif2}
                      onChange={(event)=> setUtenEditRif2(event.target.value)}/>
                      <Form.Label >Add.2</Form.Label>
                 </Form.Group>  
                 <Form.Group className="form-floating mb-3" >
                      <Form.Control type="text" value={txtUtenEditRif3}
                      onChange={(event)=> setUtenEditRif3(event.target.value)}/>
                      <Form.Label >Add.3</Form.Label>
                 </Form.Group>
               
               
              </Card.Body> 
            </Col>            
            <Col className="col-md-3" >      
              <Card.Header className="card-header" >PWA Settings</Card.Header>  
              <Card.Body className="card-body">  
                <Form.Group className="form-floating mb-3" >
                   <Form.Select
                         defaultValue={txtUtenEditDarl}
                         onChange={(event) => setUtenEditDarl(event.target.value)}
                     >
                         <option>...</option>
                         {  aSeleDarl.map((option, index) => {
                         return (<option key={option.id} value={option.CODX}>{option.DESX}</option>)
                         })  }
                     </Form.Select>
                     <Form.Label >PWA Dark/Linght</Form.Label>
                 </Form.Group>      
                 <Form.Group className="form-floating mb-3" >
                   <Form.Select
                         defaultValue={txtUtenEditThem}
                         onChange={(event) => setUtenEditThem(event.target.value)}
                     >
                         <option>...</option>
                         {  aSeleThem.map((option, index) => {
                         return (<option key={option.id} value={option.CODX}>{option.DESX}</option>)
                         })  }
                     </Form.Select>
                     <Form.Label >PWA Theme</Form.Label>
                 </Form.Group>      
                 <Form.Group className="form-floating mb-3" >
                 <Form.Select
                         defaultValue={txtUtenEditNoti}
                         onChange={(event) => setUtenEditNoti(event.target.value)}
                     >
                         <option>...</option>
                         {  aSeleNoti.map((option, index) => {
                         return (<option key={option.id} value={option.CODX}>{option.DESX}</option>)
                         })  }
                     </Form.Select>
                     <Form.Label >PWA Notifiche</Form.Label>
                 </Form.Group>     
               
               
               
              </Card.Body> 
            </Col>            
         
           
        
           </Row>      
           <ToastContainer />
          
    
           </Card> 
           { openModal &&
                    <ShowModal open={openModal} onClose={onCloseModal} 
                    tito={titoModal} text={bodyModal} 
                    ></ShowModal>
           }   
           { openModalConfirm &&
                    <ShowModalConfirm open={openModalConfirm} onClose={onCloseModalConfirm} 
                    onReturn={onReturnModalConfirm}   
                    tito={titoModal} text={bodyModal} butt={buttModal} 
                    ></ShowModalConfirm>
           }   
           { openLook &&
                    <ShowLook open={openLook} onClose={onCloseLook} 
                    tito={titoLook} step={stepLook}  
                    para1={txtUtenEditEmai} para2={""} para3={""}
                    codx={"Data"} desx={"Azione"}  
                    ></ShowLook>
           }              
     </div>     
   </div>  //div globale 
  ) //return  
             
}  //componente             

export default UtenEdit;