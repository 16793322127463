import './Home.css';
import React from "react";
import itaFlag from './../assets/ItalianFlag.png'  
import spaFlag from './../assets/SpanishFlag.png'  
import swiFlag from './../assets/SwissFlag.png'  
import engFlag from './../assets/UkFlag.png' 
import indFlag from './../assets/IndianFlag.png'  
import squareRed from './../assets/SquareRed.png'  
import HomeNavbar from './HomeNavbar.js';


const HomeSide = props => {
    const imgFlag1 = {
      margin:"3px"
   
    };

 
  return (    

    <div >
       <p id="lbzHomeTitoSect">AMG Foundation</p>
       <img src={squareRed}  width="20" height="20"/>   
       <h3 id="lbzHomeTitoCust1">AMGDEMO</h3> 
       <label id="lbzHomeTitoUten">alberto.cappelli@libero.it</label>
       <HomeNavbar></HomeNavbar>
       <br></br>
       <div className="footer" >
        <p>
         Copyright 2024 &copy;
        <br/>
        Datapartners.ch
        <br/>
        All rights reserved
        <br/>
        <img src={swiFlag}  style={imgFlag1} width="35" height="20"/>  <br/>
        <img src={itaFlag}  style={imgFlag1}   width="35" height="20"/>   
        <img src={engFlag}  style={imgFlag1}  width="35" height="20"/>   
        <img src={spaFlag}  style={imgFlag1} width="35" height="20"/>   
        <img src={indFlag}  style={imgFlag1}  width="35" height="20"/>   
        </p>
       </div>  
    </div> 

)  
             
}              

    
 

export default HomeSide;
