import './App.css';
import React, { useState,useEffect} from 'react';
import { BrowseRouter,Routes,Route} from 'react-router-dom'
import Home from './Components/Home.js';
import Ruol from './Components/Ruol.js';
import RuolEdit from './Components/RuolEdit.js';
import Soci from './Components/Soci.js';
import SociEdit from './Components/SociEdit.js';
import Sedi from './Components/Sedi.js';
import SediEdit from './Components/SediEdit.js';
import Aler from './Components/Aler.js';
import AlerEdit from './Components/AlerEdit.js';
import Carx from './Components/Card.js';
import CardEdit from './Components/CardEdit.js';
import Grup from './Components/Grup.js';
import GrupEdit from './Components/GrupEdit.js';
import Cost from './Components/Cost.js';
import CostEdit from './Components/CostEdit.js';
import Tabe from './Components/Tabe.js';
import TabeEdit from './Components/TabeEdit.js';
import Diri from './Components/Diri.js';
import DiriEdit from './Components/DiriEdit.js';
import Dict from './Components/Dict.js';
import DictEdit from './Components/DictEdit.js';
import Cale from './Components/Cale.js';
import CaleEdit from './Components/CaleEdit.js';
import Uten from './Components/Uten.js';
import UtenEdit from './Components/UtenEdit.js';
import Dash from './Components/Dash.js';
import { GlobalVariables,AMGCrypto,getParamD } from "./Components/Functions.js";
import useFetchStart from './Components/FetchStart.js';


 

function App() {  
   //questi per sveltire dev
   var cUrll='https://amg.datapartners.ch/Amg/ws/AMG_WS/'
   var cUrla='https://amg.datapartners.ch/Amg/ws/DFD/'
   var cUrls='https://amg.datapartners.ch/Amg/ws/AMG_Security/Login/'
   var cUrlp='https://www.ellegm.it/AMG_Portale/Amg/ws/PIP_Ws/'
   var cCustomer="AMGDEMO"
   var cUser='alberto.cappelli@libero.it' 
   //console.log("AVVIO")
   //const [cUrll,setCurll]=useState([""]) ;
   //lettura parametri
   var cPRM= new URLSearchParams(document.location.search)
   cPRM=decodeURIComponent(cPRM) //senon metto questo perdo i caratteri +
    //cPRM=cPRM.get("PRM") //leggo parametro ma se facico decode non va cosi
   //console.log(cPRM)
   cPRM=cPRM.substr(4) //se e' blank non ha ricevuto paremetri
   //console.log(cPRM==="") 
   if (cPRM!="") {  
      //var cPRM0="U2FsdGVkX18uaUwQqz4HUktNwvo9iP6XfWo2WZV8YYC13p4sBPgWR/jlW18CtGmIGVT2AjkOZmPo58ugwEPmQ/CFCg2gLMZesbzB7r5ysRLVITsL5uogeyZqe+eqp+A44xph4cvrUAf/hAZIV5ECAIteCBEfhIrOX1RtCX3B/394CRZ9QsYvGOwNDIWO6ngh5nB2cBUyIe5WnlvMrY8tMkaCAn1Pv2R9k5fgrfXBVrSJXMiLItD6k7nuOQ96BZqCeOPg/WSoCuy+cAWGqlMzVp+0k/9iQArIpK4I21lS3ayHbFlB7BBb2+R1ulamkjS5"
      //console.log(cPRM)
      //console.log(cPRM0)
      cPRM=AMGCrypto(cPRM,"D") //decripto
      //console.log(cPRM)
      //setCurll(getParamD(cPRM,"URLL")) 
      cUrll=getParamD(cPRM,"URLL")
      cUrla=getParamD(cPRM,"URLA")
      cUrls=getParamD(cPRM,"URLS")
      cUser=getParamD(cPRM,"UTEN") 
      cUrlp=getParamD(cPRM,"URLP")
      cCustomer=getParamD(cPRM,"CUST") 
      //console.log("AAA"+cUrll)   
      //alert(cCustomer+" "+cUser)
   };   
  
   
    
  
    //carico gli array globali da start
    const cUrl=cUrll + "AMG_start/"
   
   //alert(cUrl)
   //var aa=[1,2,3]
  
  
  // console.log(aa)
  
  //console.log(aGV)
  //setConfSoci([4,5,6])
  //aa=[44,55,66]
  //const [aConfSoci,setConfSoci]=useState(aa) ;
  
  //console.log(aa)
   const raw = JSON.stringify({
       customer: cCustomer, //"AMGDEMO", //"cust01",
       user: cUser, // "alberto.cappelli@libero.it", //"pippo",
       origin: "AMG",
       type: "S",
       keyc: "*|",
       data: "*|",
  });
 
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
  };
  const { aConfSoci,aConfUten,aConfCard,aConfSedi,aConfGrup,aConfRuol,aConfCost,aConfTabe } = useFetchStart(cUrl,requestOptions);
  //console.log(aConfCard)
  //console.log(aConfTabe)
  //alert("A"+cUrll)
  const aGV={ 
    urll: cUrll,
    urls: cUrls,
    urla: cUrla,
    urlp: cUrlp,
    customer: cCustomer,
    user: cUser,
    aConfSoci,
    aConfUten,
    aConfCard,
    aConfSedi,
    aConfGrup,
    aConfRuol,
    aConfCost,
    aConfTabe
  }  
  //console.log(aGV)
    


  return (    

    <div className="App">
       <GlobalVariables.Provider value={ aGV }>
            <Routes>             
               <Route exact path="/" element={<Home></Home>} /> 
               <Route exact path="Home" element={<Home></Home>} /> 
               <Route exact path="/Ruol" element={<Ruol></Ruol>} /> 
               <Route exact path="/RuolEdit" element={<RuolEdit></RuolEdit>} /> 
               <Route exact path="/Aler" element={<Aler></Aler>} /> 
               <Route exact path="/AlerEdit" element={<AlerEdit></AlerEdit>} /> 
               <Route exact path="/Card" element={<Carx></Carx>} /> 
               <Route exact path="/CardEdit" element={<CardEdit></CardEdit>} /> 
               <Route exact path="/Cost" element={<Cost></Cost>} /> 
               <Route exact path="/CostEdit" element={<CostEdit></CostEdit>} /> 
               <Route exact path="/Grup" element={<Grup></Grup>} /> 
               <Route exact path="/GrupEdit" element={<GrupEdit></GrupEdit>} /> 
               <Route exact path="/Soci" element={<Soci></Soci>} /> 
               <Route exact path="/SociEdit" element={<SociEdit></SociEdit>} /> 
               <Route exact path="/Sedi" element={<Sedi></Sedi>} /> 
               <Route exact path="/SediEdit" element={<SediEdit></SediEdit>} /> 
               <Route exact path="/Tabe" element={<Tabe></Tabe>} /> 
               <Route exact path="/TabeEdit" element={<TabeEdit></TabeEdit>} /> 
               <Route exact path="/Diri" element={<Diri></Diri>} /> 
               <Route exact path="/DiriEdit" element={<DiriEdit></DiriEdit>} /> 
               <Route exact path="/Dict" element={<Dict></Dict>} /> 
               <Route exact path="/DictEdit" element={<DictEdit></DictEdit>} />
               <Route exact path="/Cale" element={<Cale></Cale>} /> 
               <Route exact path="/CaleEdit" element={<CaleEdit></CaleEdit>} /> 
               <Route exact path="/Uten" element={<Uten></Uten>} /> 
               <Route exact path="/UtenEdit" element={<UtenEdit></UtenEdit>} /> 
               <Route exact path="/Dash" element={<Dash></Dash>} /> 
            </Routes>  
       </GlobalVariables.Provider>   
         
    
        

    </div> 
   
)  
             
}              

    
 

export default App;
