import './../App.css';
import HomeSide from './HomeSide.js';
import React, { useState ,useEffect,useContext} from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import { FaPen } from 'react-icons/fa';
import { Card} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { GlobalVariables,lenReducer } from './Functions.js';

const Dash = props => {
  const [getEmbedUrl, setGetEmbedUrl] = useState("");
  //variabili globali
  const aGV= useContext(GlobalVariables)
  
  //definizioni per tabella
  //const [aConfDict,setConfDict]=useState([]) ;y
  //lancio della chiamata della dashboard per ora fissa
  var cUrlDash="https://amgenaispacebackend.datapartners.ch/quicksight/?user=davide.donadio@datapartners.ch&awsAcc_id=019219877569"
  //cUrlDash=cUrlDash+"&dash_id=6b40f4be-d399-467f-aca4-56600e197fdf" //sease
  cUrlDash=cUrlDash+"&dash_id=8fd51ab8-b86d-4860-b76f-da1d8b82b17b" //log
  const cSrc="" //https://eu-west-1.quicksight.aws.amazon.com/embed/5ff49023dc674245a6a8e31b894eacfe/dashboards/6b40f4be-d399-467f-aca4-56600e197fdf"
  useEffect(() => {
    fetch(cUrlDash)
    .then(response =>  {  return  response.json()  }) 
    .then( data => { 
            //console.log(data.body)
            if (data.body) {
              const embedData = JSON.parse(data.body);
              setGetEmbedUrl(() => embedData?.EmbedUrl);
            } else {
              if (data.slice(0, 5) !== "Error")
                alert("Failed to fetch quicksight Embeddings");
                console.log(data);
            }
            
    })
    .catch( err =>  {  
            alert(err.message)
    })
},[]);
 

  return (    
    
    <div style={window.$stiTop} > 
        <div style={window.$stiLeft}>
            <HomeSide></HomeSide>
        </div> 
        <div style={window.$stiRight}>
           <h2  >AMG Foundation Dashboard</h2>
           <hr id="hrAppTito" />
           <Card className="shadow p-3 mb-5 bg-white rounded">
              <Card.Body>
              <iframe src={ getEmbedUrl }  height="600px" width="100%" ></iframe> 
              </Card.Body>
           </Card>
       </div>   
    </div>  // div globale
  
  )  //return 

}    //componente          
export default Dash;