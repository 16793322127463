import './../App.css';
import React, { useState ,useEffect,useContext} from 'react';
import HomeSide from './HomeSide.js';
import { Card,Button,ButtonGroup} from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useNavigate } from "react-router-dom";
import {useLocation} from 'react-router-dom';
import {notifyToast,convErrorMessage} from './Functions.js';
import { ToastContainer } from 'react-toastify';
import { GlobalVariables,convPuntBlank,replApice } from './Functions.js';
import ShowModal from './ShowAlert.js'; 
import ShowModalConfirm from './ShowConfirm.js'; 


const TabeEdit = ()  => { //in uselocation.state ho opzi=I U D  indi=ndice  data=record corrente
     //variabili globali
     const aGV= useContext(GlobalVariables)

     //parametri di chiamata  I U D
     const location = useLocation();
     const datx=location.state.data
     
     //cCurrOpzi lo cambio se elimino
     const [cCurrOpzi, setCurrOpzi] = useState(location.state.opzi);
     
     var cCurrOpziTito="Choice: "+datx.TABE+" "+datx.CODX
     if (cCurrOpzi==='I'){
       cCurrOpziTito="Nuova choice"
       datx.SOCI=""
       datx.TABE=""
       datx.CODX=""
       datx.DESX=""
       datx.VALO=""
       datx.NOTX=""
       datx.DEFA=""
       datx.COLB=""
       datx.COLF=""
       datx.RIF1=""
       datx.RIF2=""
       datx.RIF3=""
      
      
     }
     //parametri router
     const navigate = useNavigate(); 
     
     //parametri modal 
     const [openModal, setOpenModal] = useState(false);
     const onOpenModal = () => setOpenModal(true);
     const onCloseModal = () => setOpenModal(false);
    
     //parametri modal confirm
     const [openModalConfirm, setOpenModalConfirm] = useState(false);
     const onOpenModalConfirm = () => setOpenModalConfirm(true);
     const onCloseModalConfirm = () => setOpenModalConfirm(false);
     const onReturnModalConfirm = () => 
     { //conferma alla eliminazione
          setOpenModalConfirm(false);
          //console.log(cCurrOpzi)
          saveTabe() 
     }  
     //variabili dell'oggetto
     //se nuovo metto blank altrimento assegno
     const [txtTabeEditSoci, setTabeEditSoci] = useState(datx.SOCI);
     const [txtTabeEditTabe, setTabeEditTabe] = useState(datx.TABE);
     const [txtTabeEditCodx, setTabeEditCodx] = useState(datx.CODX);
     const [txtTabeEditDesx, setTabeEditDesx] = useState(datx.DESX);
     const [txtTabeEditValo, setTabeEditValo] = useState(datx.VALO);
     const [txtTabeEditNotx, setTabeEditNotx] = useState(datx.NOTX);
     const [txtTabeEditDefa, setTabeEditDefa] = useState(datx.DEFA);
     const [txtTabeEditColb, setTabeEditColb] = useState(datx.COLB);
     const [txtTabeEditColf, setTabeEditColf] = useState(datx.COLF);
     const [txtTabeEditRif1, setTabeEditRif1] = useState(datx.RIF1);
     const [txtTabeEditRif2, setTabeEditRif2] = useState(datx.RIF2);
     const [txtTabeEditRif3, setTabeEditRif3] = useState(datx.RIF3);
    
     const [titoModal, setTitoModal] = useState("");
     const [bodyModal, setBodyModal] = useState("");
     const [buttModal, setButtModalConfirm] = useState("Conferma");
    
     //funzioni sele
     const aSeleSoci=aGV.aConfSoci
     const aSeleDefa=[ 
          { id:0, CODX: '1', DESX:'S' },
          { id:1, CODX: '0', DESX:'N' }
     ];

     //funzioni routing
     function handleBtnTabeEditAggiClick() {  //funzione Aggiorna
         checkTabe()
     }
     function handleBtnTabeEditChiuClick() {  //funzione chiudi
         navigate("/Tabe");
     }
     function handleBtnTabeEditElimClick() {  //funzione elimina
          setTitoModal("Attenzione")
          setBodyModal("Conferma eliminazione choice "+txtTabeEditSoci+"/"+txtTabeEditCodx+"?");
          setCurrOpzi("D")
          //alert("PPPP")
          onOpenModalConfirm()
     }
 
     //funzioni check save
     function checkTabe() {   //controllo dati
         var cErro=""
         var cLabe=""
         //alert(txtTabeEditSoci)
         if (txtTabeEditSoci.trim()===''){
             cErro=cErro+("Selezionare società **")
             cLabe=cLabe+"#lblConfTabeEditSoci|"
         }
         if (txtTabeEditTabe.trim()===''){
             cErro=cErro+("Digitare tabella **")
             cLabe=cLabe+"#lblConfTabeEditTabe|"
         }
         if (txtTabeEditCodx.trim()===''){
               cErro=cErro+("Digitare codice **")
               cLabe=cLabe+"#lblConfTabeEditCodx|"
         }
         if (txtTabeEditDefa.trim()===''){
          cErro=cErro+("Selezionare Default S/N **")
          cLabe=cLabe+"#lblConfTabeEditDefa|"
         }
         
         
         if (cErro===''){
               saveTabe()
         } else {
               notifyToast(cErro)  
         }
   
     }
     function saveTabe() { //salvataggio
          //alert(cCurrOpzi)
          //return
          var cData=""
          var cKeyc=""
          if (cCurrOpzi==='I'){ //insert
                cKeyc= "company;tableCode;itemCode|'"+txtTabeEditSoci+"';'"+txtTabeEditTabe+"';'"+txtTabeEditCodx+"'"
                cData= "company;tableCode;itemCode;itemDescription;itemValue;notes;defaultValue;backgroundcolor;forecolor;rif1;rif2;rif3|"
                cData=cData +"'" +txtTabeEditSoci+"';"
                cData=cData +"'" +txtTabeEditTabe+"';"
                cData=cData +"'" +txtTabeEditCodx+"';"
                cData=cData +"'" +replApice(txtTabeEditDesx)+"';"
                cData=cData +"'" +replApice(txtTabeEditValo)+"';"
                cData=cData +"'" +replApice(txtTabeEditNotx)+"';"
                cData=cData +"'" +txtTabeEditDefa+"';"
                cData=cData +"'" +txtTabeEditColb+"';"
                cData=cData +"'" +txtTabeEditColf+"';"
                cData=cData +"'" +txtTabeEditRif1+"';"
                cData=cData +"'" +txtTabeEditRif2+"';"
                cData=cData +"'" +txtTabeEditRif3+"';"
               
               
          }      
          if (cCurrOpzi==='U'){ //update
                cKeyc= "company;tableCode;itemCode|'"+txtTabeEditSoci+"';'"+txtTabeEditTabe+"';'"+txtTabeEditCodx+"'"
                cData= "itemDescription;itemValue;notes;defaultValue;backgroundcolor;forecolor;rif1;rif2;rif3|"
                cData=cData +"'" +replApice(txtTabeEditDesx)+"';"
                cData=cData +"'" +replApice(txtTabeEditValo)+"';"
                cData=cData +"'" +replApice(txtTabeEditNotx)+"';"
                cData=cData +"'" +txtTabeEditDefa+"';"
                cData=cData +"'" +txtTabeEditColb+"';"
                cData=cData +"'" +txtTabeEditColf+"';"
                cData=cData +"'" +txtTabeEditRif1+"';"
                cData=cData +"'" +txtTabeEditRif2+"';"
                cData=cData +"'" +txtTabeEditRif3+"';"
          }  
          if (cCurrOpzi==='D')  { //elimina
               cKeyc= "company;tableCode;itemCode|'"+txtTabeEditSoci+"';'"+txtTabeEditTabe+"';'"+txtTabeEditCodx+"'"
               cData= "|"
          }
         
          var myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/json");
          var raw = JSON.stringify({
             customer: aGV.customer, //"AMGDEMO", //"cust01",
             user: aGV.user, //"alberto.cappelli@libero.it", //"pippo",
             type: cCurrOpzi,    // UDI
             keyc: cKeyc,
             data: cData,
          });
          var requestOptions = {
             method: "POST",
             headers: myHeaders,
             body: raw,
             redirect: "follow",
          };
          const cUrl=aGV.urll+"AMG_Tables" //"https://amg.datapartners.ch/Amg/ws/AMG_WS/AMG_Roles"
          fetch(cUrl,requestOptions)
               //console.log(data)
               .then(response =>  { return response.json()  }) 
               .then( datiricevuti => { 
                    //in datiricevuti.error manda  errori es codice esistente
                    if (datiricevuti.error!=='') { 
                         //console.log(datiricevuti.error)
                         setTitoModal("Attenzione")
                         setBodyModal(convErrorMessage(datiricevuti.error))
                         onOpenModal()
                    } else { 
                          handleBtnTabeEditChiuClick()
                    }      
               })
               .catch( err =>  {  
                    //console.log(err)
                    setTitoModal("Attenzione")
                    setBodyModal(err.message)
                    onOpenModal()
               })
          }   
  return (    
    <div style={window.$stiTop} > 
        <div style={window.$stiLeft}>
            <HomeSide></HomeSide>
        </div> 
        <div style={window.$stiRight} >
           <h2  >AMG Foundation Sedi</h2>
           <hr />
           <h4 >{cCurrOpziTito}</h4>
           <div className="divDummyAlign">
             <ButtonGroup   aria-label="Button Group" className="btnGroup">
               <Button variant="danger" size="sm"  onClick={handleBtnTabeEditAggiClick}>Aggiorna</Button>    
               <Button variant="danger" size="sm"  onClick={handleBtnTabeEditElimClick}
               hidden={cCurrOpzi==='U' ? false : true}
               >Elimina</Button>              
               <Button variant="secondary" size="sm"  onClick={handleBtnTabeEditChiuClick}> Chiudi</Button>
             </ButtonGroup>
           </div>
           <pre></pre>
           <Card className="shadow p-3 mb-5 bg-white rounded">
             <Row >
             <Col className="col-md-4" >      
              <Card.Header className="card-header" >Dati base</Card.Header>  
              <Card.Body className="card-body">  
                 <Form.Group className="form-floating mb-3" >
                     <Form.Select
                         disabled={cCurrOpzi==='U' ? true : false}
                         defaultValue={txtTabeEditSoci}
                         onChange={(event) => setTabeEditSoci(event.target.value)}
                     >
                         <option>...</option>
                         {  aSeleSoci.map((option, index) => {
                         return (<option key={option.id} value={option.CODX}>{option.DESX}</option>)
                         })  }
                     </Form.Select>
                     <Form.Label >Società</Form.Label>
                 </Form.Group>
                 <Form.Group className="form-floating mb-3" >
                      <Form.Control type="text" value={txtTabeEditTabe}
                      disabled={cCurrOpzi==='U' ? true : false}
                      onChange={(event)=> setTabeEditTabe(event.target.value)}/>
                      <Form.Label >Codice tabella</Form.Label>
                 </Form.Group>  
                <Form.Group className="form-floating mb-3" >
                      <Form.Control type="text" value={txtTabeEditCodx}
                      disabled={cCurrOpzi==='U' ? true : false}
                      onChange={(event)=> setTabeEditCodx(event.target.value)}/>
                      <Form.Label >Codice item</Form.Label>
                 </Form.Group>  
                 <Form.Group className="form-floating mb-3" >
                      <Form.Control type="text" value={txtTabeEditValo}
                       onChange={(event)=> setTabeEditValo(event.target.value)}/>
                      <Form.Label >Valore item</Form.Label>
                 </Form.Group>  
                
                 <Form.Group className="form-floating mb-3" >
                      <Form.Control type="text" value={txtTabeEditDesx}
                       onChange={(event)=> setTabeEditDesx(event.target.value)}/>
                      <Form.Label >Descrizione (mnemonica)</Form.Label>
                 </Form.Group>  
                 <Form.Group className="form-floating mb-3" >
                      <Form.Control type="text" value={txtTabeEditNotx}
                       onChange={(event)=> setTabeEditNotx(event.target.value)}/>
                      <Form.Label >Note</Form.Label>
                 </Form.Group>  
               </Card.Body> 
            </Col>    
            <Col className="col-md-4" >      
              <Card.Header className="card-header" >Altri dati</Card.Header>  
              <Card.Body className="card-body">  
                 <Form.Group className="form-floating mb-3" >
                      <Form.Control type="text"  value={txtTabeEditColb}
                      onChange={(event)=> setTabeEditColb(event.target.value)}/>
                      <Form.Label >Add.1</Form.Label>
                 </Form.Group>  
                 <Form.Group className="form-floating mb-3" >
                      <Form.Control type="text" value={txtTabeEditColf}
                      onChange={(event)=> setTabeEditColf(event.target.value)}/>
                      <Form.Label >Colore sfondo</Form.Label>
                 </Form.Group>  
                 <Form.Group className="form-floating mb-3" >
                     <Form.Select
                        
                         defaultValue={txtTabeEditDefa}
                         onChange={(event) => setTabeEditDefa(event.target.value)}
                     >
                         <option>...</option>
                         {  aSeleDefa.map((option, index) => {
                         return (<option key={option.id} value={option.CODX}>{option.DESX}</option>)
                         })  }
                     </Form.Select>
                      <Form.Label >Valore default (S/N)</Form.Label>
                 </Form.Group>  
               
              </Card.Body> 
            </Col>            
            <Col className="col-md-4" >      
              <Card.Header className="card-header" >Dati addizionali</Card.Header>  
              <Card.Body className="card-body">  
                 <Form.Group className="form-floating mb-3" >
                      <Form.Control type="text"  value={txtTabeEditRif1}
                      onChange={(event)=> setTabeEditRif1(event.target.value)}/>
                      <Form.Label >Add.1</Form.Label>
                 </Form.Group>  
                 <Form.Group className="form-floating mb-3" >
                      <Form.Control type="text" value={txtTabeEditRif2}
                      onChange={(event)=> setTabeEditRif2(event.target.value)}/>
                      <Form.Label >Add.2</Form.Label>
                 </Form.Group>  
                 <Form.Group className="form-floating mb-3" >
                      <Form.Control type="text" value={txtTabeEditRif3}
                      onChange={(event)=> setTabeEditRif3(event.target.value)}/>
                      <Form.Label >Add.3</Form.Label>
                 </Form.Group>  
               
              </Card.Body> 
            </Col>            
         
           
        
           </Row>      
           <ToastContainer />
          
    
           </Card> 
           { openModal &&
                    <ShowModal open={openModal} onClose={onCloseModal} 
                    tito={titoModal} text={bodyModal} 
                    ></ShowModal>
           }   
           { openModalConfirm &&
                    <ShowModalConfirm open={openModalConfirm} onClose={onCloseModalConfirm} 
                    onReturn={onReturnModalConfirm}   
                    tito={titoModal} text={bodyModal} butt={buttModal} 
                    ></ShowModalConfirm>
           }              
     </div>   
   </div>  //div globale 
  ) //return  
             
}  //componente             

export default TabeEdit;