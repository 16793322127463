import './../App.css';
import HomeSide from './HomeSide.js';
import React, { useState ,useEffect,useContext} from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import { FaTimes,FaPen } from 'react-icons/fa';
import { Card} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import ShowModal from './ShowAlert.js';
import { GlobalVariables } from './Functions.js';

const Ruol = props => {
  //variabili globali
  //const { aGV, setGV } = useContext(GlobalVariables);
  //var aConfRuol=[]
  //const aGV=GlobalVariables.value
  const aGV= useContext(GlobalVariables)
  
  //definizioni per tabella
  //const [data,setData]=useState(null)  ;
  const [aConfRuol,setConfRuol]=useState([]) ;
  //const [error,setError]=useState(true) ;
 

  //definizioni per modale
  const [openModal, setOpenModal] = useState(false);
  const onOpenModal = () => setOpenModal(true);
  const onCloseModal = () => setOpenModal(false);
  const [titoModal, setTitoModal] = useState("");
  const [bodyModal, setBodyModal] = useState("aa");
  //var aConfRuol=[]
 // useEffect(() => {
   // console.log(openModal);
    //onCloseModal()
  //});
  
  //alert(Functions.convDataMGA("12/01/2024","/"))


  //router
  const navigate = useNavigate(); 
  
  //funzioni Nuovo e Modifica 
  function handleBtnRuolNuovClick() {
      navigate('/RuolEdit', {state: {opzi:'I', row:-1,data:[]}});
  }
  function handleRuolEditClick(opzx,rowx){// DELE/MODI e numero index
      const datx=aConfRuol[rowx.id]
      navigate('/RuolEdit', {state: {opzi:opzx, row:rowx.id,data:datx}});
  }

  function iconTable(cell, row, rowIndex) {
    return (
      <div>
      <button id="rowModi"  onClick={() => handleRuolEditClick("U",row)} className="btn btn-danger btn-sm " > <FaPen></FaPen></button>
      
      </div>   
    );
  }
 
  
  const columns = [
    {
      dataField: "OPZI",
      text: "Action",
      sort: false,
      formatter: iconTable,
      headerStyle: (colum, colIndex) => {
        return { width: '5%', textAlign: 'center' };
      }
    },
    {
      dataField: "SOCI",
      text: "Società",
      sort: true
     
    
    },
    {
      dataField: "CODX",
      text: "Codice",
      sort: true,
      style: {
        fontWeight: 'bold'
        //fontSize: '18px'
      },
      filter: textFilter({
        delay: 500, // default is 500ms
        style: {
          backgroundColor: '#ffffff'
        }
     
       
      })
     
    },
    {
      dataField: "DESX",
      text: "Descrizione", 
      sort: true,
      filter: textFilter({
        delay: 500, // default is 500ms
        style: {
          backgroundColor: '#ffffff'
        }
       
      })
     
    
    },
    {
      dataField: "RIF1",
      text: "Add.1"
    },
    {
      dataField: "RIF2",
      text: "Add.2"
    },
    {
      dataField: "RIF3",
      text: "Add.3" 
    }
    
  ]
  
  //lettura tabella
  //console.log(aGV)
  //alert(aGV.urlllength)
 //console.log(aGV.aConfSoci)


  const cUrl=aGV.urll +"AMG_Roles" //"https://amg.datapartners.ch/Amg/ws/AMG_WS/AMG_Roles"
  //alert(cUrl)
 
  const raw = JSON.stringify({
       customer: aGV.customer, //"AMGDEMO", //"cust01",
       user: aGV.user, // "alberto.cappelli@libero.it", //"pippo",
       type: "S",
       keyc: "*|",
       data: "*|",
  });
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
  };
  //console.log(cUrl)
  //console.log(raw)
  //return 
  //console.log(cUrl)
  useEffect(() => {
    fetch(cUrl,requestOptions)
    .then(response =>  { return response.json()  }) 
    .then( data => { 
             const aData=data.roles //data[attx] 
             //console.log(aData)
             //setData(aData)
            
             if(aData!=null){
               //console.log(aData.length)
               var aD=[]
               for (var i = 0; i < aData.length; i++) {
                    var row = aData[i];
                   
                    if (row!=null) {//console.log(row.code + " - " + row.description);
                        aD.push({
                            id: i,
                            OPZI: "",
                            SOCI: row.company,
                            CODX: row.code,
                            DESX: row.description,
                            RIF1: row.rif1,
                            RIF2: row.rif2,
                            RIF3: row.rif3,
                        });
                        
                    }   
               } //loop
               setConfRuol(aD)
              
            } //data is noy null
    })
    .catch( err =>  {  
               alert(err.message)
   })
},[]);
 
 

 

  
 
  //const { ExportCSVButton } = CSVExport;
  return (    
    
    <div style={window.$stiTop} > 
        <div style={window.$stiLeft}>
            <HomeSide></HomeSide>
        </div> 
        <div style={window.$stiRight}>
           <h2  id="lbzAppTito">AMG Foundation Ruoli</h2>
           <hr id="hrAppTito" />
            <Card className="shadow p-3 mb-5 bg-white rounded">
          
            


      <Card.Body>
      <button className="btn btn-danger btn-sm " style={{position: 'absolute', left: 0,top:0}}
      onClick={handleBtnRuolNuovClick}>Nuovo</button>
       <hr></hr>
    
      <div>
      
          <BootstrapTable
          
        bootstrap4
        keyField="id" 
        data={aConfRuol}
        columns={columns}
        headerClasses="table tableheadergrey"
        filter={ filterFactory() } 
        filterPosition="top"
        hover
        condensed
      />
   
        </div>
    
        </Card.Body>
    </Card>
    </div>  
    { openModal &&
          <ShowModal open={openModal} onClose={onCloseModal} 
           tito={titoModal} text={bodyModal} 
          ></ShowModal>}  


</div>  


  )  
             
}              

    
 

export default Ruol;