import './../App.css';
import React, { useState ,useEffect,useContext} from 'react';
import HomeSide from './HomeSide.js';
import { Card,Button,ButtonGroup} from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useNavigate } from "react-router-dom";
import {useLocation} from 'react-router-dom';
import {notifyToast,convErrorMessage} from './Functions.js';
import { ToastContainer } from 'react-toastify';
import { GlobalVariables,convPuntBlank,replApice } from './Functions.js';
import ShowModal from './ShowAlert.js'; 
import ShowModalConfirm from './ShowConfirm.js'; 


const DiriEdit = ()  => { //in uselocation.state ho opzi=I U D  indi=ndice  data=record corrente
     //variabili globali
     const aGV= useContext(GlobalVariables)

     //parametri di chiamata  I U D
     const location = useLocation();
     const datx=location.state.data
     
     //cCurrOpzi lo cambio se elimino
     const [cCurrOpzi, setCurrOpzi] = useState(location.state.opzi);
     
     var cCurrOpziTito="Diritto: "+datx.CODX
     if (cCurrOpzi==='I'){
       cCurrOpziTito="Nuovo diritto"
       datx.SOCI=""
       datx.CODX=""
       datx.DESX=""
       datx.TIPO=""
       datx.OGGE=""
       datx.RUOL=""
       datx.HTML=""
      
      
     }
     //parametri router
     const navigate = useNavigate(); 
     
     //parametri modal 
     const [openModal, setOpenModal] = useState(false);
     const onOpenModal = () => setOpenModal(true);
     const onCloseModal = () => setOpenModal(false);
    
     //parametri modal confirm
     const [openModalConfirm, setOpenModalConfirm] = useState(false);
     const onOpenModalConfirm = () => setOpenModalConfirm(true);
     const onCloseModalConfirm = () => setOpenModalConfirm(false);
     const onReturnModalConfirm = () => 
     { //conferma alla eliminazione
          setOpenModalConfirm(false);
          //console.log(cCurrOpzi)
          saveDiri() 
     }  
     //variabili dell'oggetto
     //se nuovo metto blank altrimento assegno
     const [txtDiriEditSoci, setDiriEditSoci] = useState(datx.SOCI);
     const [txtDiriEditCodx, setDiriEditCodx] = useState(datx.CODX);
     const [txtDiriEditDesx, setDiriEditDesx] = useState(datx.DESX);
     const [txtDiriEditTipo, setDiriEditTipo] = useState(datx.TIPO);
     const [txtDiriEditOgge, setDiriEditOgge] = useState(datx.OGGE);
     const [txtDiriEditRuol, setDiriEditRuol] = useState(datx.RUOL);
     const [txtDiriEditHtml, setDiriEditHtml] = useState(datx.HTML);
     


     const [titoModal, setTitoModal] = useState("");
     const [bodyModal, setBodyModal] = useState("");
     const [buttModal, setButtModalConfirm] = useState("Conferma");
    
     //funzioni sele
     const aSeleSoci=aGV.aConfSoci
     const aSeleRuol=aGV.aConfRuol
     const aSeleTipo = [ 
          { "id": "0","CODX": "R","DESX": "Diritto"},
          { "id": "1","CODX": "V","DESX": "Visibility"},
     ]

     //funzioni routing
     function handleBtnDiriEditAggiClick() {  //funzione Aggiorna
         checkDiri()
     }
     function handleBtnDiriEditChiuClick() {  //funzione chiudi
         navigate("/Diri");
     }
     function handleBtnDiriEditElimClick() {  //funzione elimina
          setTitoModal("Attenzione")
          setBodyModal("Conferma eliminazione diritto "+txtDiriEditCodx+"?");
          setCurrOpzi("D")
          //alert("PPPP")
          onOpenModalConfirm()
     }
 
     //funzioni check save
     function checkDiri() {   //controllo dati
         var cErro=""
         var cLabe=""
         if (txtDiriEditSoci.trim()===''){
             cErro=cErro+("Selezionare società **")
             cLabe=cLabe+"#lblConfDiriEditSoci|"
    }
         if (txtDiriEditCodx.trim()===''){
               cErro=cErro+("Digitare codice **")
               cLabe=cLabe+"#lblConfDiriEditCodx|"
         }
         if (txtDiriEditDesx.trim()===''){
               cErro=cErro+("Digitare descrizione **")
               cLabe=cLabe+"#lblConfDiriEditDesx|" 
         }
         
         if (cErro===''){
               saveDiri()
         } else {
               notifyToast(cErro)  
         }
   
     }
     function saveDiri() { //salvataggio
          //alert(cCurrOpzi)
          //return
          var cData=""
          var cKeyc=""
          if (cCurrOpzi==='I'){ //insert
                cKeyc= "company;code|'"+txtDiriEditSoci+"';'"+txtDiriEditCodx+"'"
                cData= "company;code;type;description;roles;objects;html|"
                cData=cData +"'" +txtDiriEditSoci+"';"
                cData=cData +"'" +txtDiriEditCodx+"';"
                cData=cData +"'" +txtDiriEditTipo+"';"
                cData=cData +"'" +txtDiriEditDesx+"';"
                cData=cData +"'" +txtDiriEditRuol+"';"
                cData=cData +"'" +txtDiriEditOgge+"';"
                cData=cData +"'" +txtDiriEditHtml+"';"
               
          }      
          if (cCurrOpzi==='U'){ //update
                cKeyc= "company;code|'"+txtDiriEditSoci+"';'"+txtDiriEditCodx+"'"
                cData= "type;description;roles;objects;html|"
                cData=cData +"'" +txtDiriEditTipo+"';"
                cData=cData +"'" +txtDiriEditDesx+"';"
                cData=cData +"'" +txtDiriEditRuol+"';"
                cData=cData +"'" +txtDiriEditOgge+"';"
                cData=cData +"'" +txtDiriEditHtml+"';"
          }  
          if (cCurrOpzi==='D')  { //elimina
                cKeyc= "company;code|'"+txtDiriEditSoci+"';'"+txtDiriEditCodx+"'"
                cData= "|"
          }
         
          var myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/json");
          var raw = JSON.stringify({
             customer: aGV.customer, //"AMGDEMO", //"cust01",
             user: aGV.user, //"alberto.cappelli@libero.it", //"pippo",
             type: cCurrOpzi,    // UDI
             keyc: cKeyc,
             data: cData,
          });
          var requestOptions = {
             method: "POST",
             headers: myHeaders,
             body: raw,
             redirect: "follow",
          };
          const cUrl=aGV.urll+"AMG_Rights" //"https://amg.datapartners.ch/Amg/ws/AMG_WS/AMG_Roles"
          //console.log(raw)
          fetch(cUrl,requestOptions)
               //console.log(data)
               .then(response =>  { return response.json()  }) 
               .then( datiricevuti => { 
                    //in datiricevuti.error manda  errori es codice esistente
                    if (datiricevuti.error!=='') { 
                         //console.log(datiricevuti.error)
                         setTitoModal("Attenzione")
                         setBodyModal(convErrorMessage(datiricevuti.error))
                         onOpenModal()
                    } else { 
                          handleBtnDiriEditChiuClick()
                    }      
               })
               .catch( err =>  {  
                    //console.log(err)
                    setTitoModal("Attenzione")
                    setBodyModal(err.message)
                    onOpenModal()
               })
          }   
  return (    
    <div style={window.$stiTop} > 
        <div style={window.$stiLeft}>
            <HomeSide></HomeSide>
        </div> 
        <div style={window.$stiRight} >
           <h2  >AMG Foundation Sicurezze</h2>
           <hr />
           <h4 >{cCurrOpziTito}</h4>
           <div className="divDummyAlign">
             <ButtonGroup   aria-label="Button Group" className="btnGroup">
               <Button variant="danger" size="sm"  onClick={handleBtnDiriEditAggiClick}>Aggiorna</Button>    
               <Button variant="danger" size="sm"  onClick={handleBtnDiriEditElimClick}
                hidden={cCurrOpzi==='U' ? false : true}
               >Elimina</Button>              
               <Button variant="secondary" size="sm"  onClick={handleBtnDiriEditChiuClick}> Chiudi</Button>
             </ButtonGroup>
           </div>
           <pre></pre>
           <Card className="shadow p-3 mb-5 bg-white rounded">
             <Row >
             <Col className="col-md-4" >      
              <Card.Header className="card-header" >Dati base</Card.Header>  
              <Card.Body className="card-body">  
                 <Form.Group className="form-floating mb-3" >
                     <Form.Select
                         disabled={cCurrOpzi==='U' ? true : false}
                         defaultValue={txtDiriEditSoci}
                         onChange={(event) => setDiriEditSoci(event.target.value)}
                     >
                         <option>...</option>
                         {  aSeleSoci.map((option, index) => {
                         return (<option key={option.id} value={option.CODX}>{option.DESX}</option>)
                         })  }
                     </Form.Select>
                     <Form.Label >Società</Form.Label>
                 </Form.Group>
                <Form.Group className="form-floating mb-3" >
                      <Form.Control type="text" value={txtDiriEditCodx}
                      disabled={cCurrOpzi==='U' ? true : false}
                      onChange={(event)=> setDiriEditCodx(event.target.value)}/>
                      <Form.Label >Codice</Form.Label>
                 </Form.Group>  
                
                 <Form.Group className="form-floating mb-3" >
                      <Form.Control type="text" value={txtDiriEditDesx}
                       onChange={(event)=> setDiriEditDesx(event.target.value)}/>
                      <Form.Label >Descrizione</Form.Label>
                 </Form.Group>  
                 <Form.Group className="form-floating mb-3" >
                    <Form.Select
                         defaultValue={txtDiriEditTipo}
                         onChange={(event) => setDiriEditTipo(event.target.value)}
                     >
                         <option>...</option>
                         {  aSeleTipo.map((option, index) => {
                         return (<option key={option.id} value={option.CODX}>{option.DESX}</option>)
                         })  }
                     </Form.Select>
                      <Form.Label >Tipo</Form.Label>
                 </Form.Group>  
               </Card.Body> 
            </Col>    
            <Col className="col-md-4" >      
              <Card.Header className="card-header" >Altri dati</Card.Header>  
              <Card.Body className="card-body">  
                 <Form.Group className="form-floating mb-3" >
                     <Form.Select
                         defaultValue={txtDiriEditRuol}
                         onChange={(event) => setDiriEditRuol(event.target.value)}
                     >
                         <option>...</option>
                         {  aSeleRuol.map((option, index) => {
                         return (<option key={option.id} value={option.CODX}>{option.DESX}</option>)
                         })  }
                     </Form.Select>
                      <Form.Label >Ruoli</Form.Label>
                 </Form.Group>  
                 <Form.Group className="form-floating mb-3" >
                      <Form.Control type="text" value={txtDiriEditOgge}
                      onChange={(event)=> setDiriEditOgge(event.target.value)}/>
                      <Form.Label >Oggetti visibility</Form.Label>
                 </Form.Group>  
                 <Form.Group className="form-floating mb-3" >
                      <Form.Control type="text" value={txtDiriEditHtml}
                      onChange={(event)=> setDiriEditHtml(event.target.value)}/>
                      <Form.Label >Oggetti HTML</Form.Label>
                 </Form.Group>  
               
              </Card.Body> 
            </Col>            
         
           
        
           </Row>      
           <ToastContainer />
          
    
           </Card> 
           { openModal &&
                    <ShowModal open={openModal} onClose={onCloseModal} 
                    tito={titoModal} text={bodyModal} 
                    ></ShowModal>
           }   
           { openModalConfirm &&
                    <ShowModalConfirm open={openModalConfirm} onClose={onCloseModalConfirm} 
                    onReturn={onReturnModalConfirm}   
                    tito={titoModal} text={bodyModal} butt={buttModal} 
                    ></ShowModalConfirm>
           }              
     </div>   //div right  
   </div>  //div globale 
  ) //return  
             
}  //componente             

export default DiriEdit;