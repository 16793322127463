import './../App.css';
import React, { useState ,useEffect,useContext} from 'react';
import HomeSide from './HomeSide.js';
import { Card,Button,ButtonGroup} from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useNavigate } from "react-router-dom";
import {useLocation} from 'react-router-dom';
import {notifyToast,convErrorMessage} from './Functions.js';
import { ToastContainer } from 'react-toastify';
import { GlobalVariables,convDatyMGA } from './Functions.js';
import ShowModal from './ShowAlert.js'; 
import ShowModalConfirm from './ShowConfirm.js'; 


const CaleEdit = ()  => { //in uselocation.state ho opzi=I U D  indi=ndice  data=record corrente
     //variabili globali
     const aGV= useContext(GlobalVariables)

     //parametri di chiamata  I U D
     const location = useLocation();
     const datx=location.state.data
     
     //cCurrOpzi lo cambio se elimino
     const [cCurrOpzi, setCurrOpzi] = useState(location.state.opzi);
     
     var cCurrOpziTito="Data: "+datx.DATR
     if (cCurrOpzi==='I'){
       cCurrOpziTito="Nuova sede"
       datx.SOCI=""
       datx.SEDE=""
       datx.DATX=""
       datx.DATR=""
      
      
      
     }
     //parametri router
     const navigate = useNavigate(); 
     
     //parametri modal 
     const [openModal, setOpenModal] = useState(false);
     const onOpenModal = () => setOpenModal(true);
     const onCloseModal = () => setOpenModal(false);
    
     //parametri modal confirm
     const [openModalConfirm, setOpenModalConfirm] = useState(false);
     const onOpenModalConfirm = () => setOpenModalConfirm(true);
     const onCloseModalConfirm = () => setOpenModalConfirm(false);
     const onReturnModalConfirm = () => 
     { //conferma alla eliminazione
          setOpenModalConfirm(false);
          //console.log(cCurrOpzi)
          saveCale() 
     }  
     //variabili dell'oggetto
     //se nuovo metto blank altrimento assegno
     const [txtCaleEditSoci, setCaleEditSoci] = useState(datx.SOCI);
     const [txtCaleEditSede, setCaleEditSede] = useState(datx.SEDE);
     const [txtCaleEditDatx, setCaleEditDatx] = useState(datx.DATX);
     const [txtCaleEditDatr, setCaleEditDatr] = useState(datx.DATR);
     //alert(datx.DATX)
     //alert(txtCaleEditDatx)
   
     const [titoModal, setTitoModal] = useState("");
     const [bodyModal, setBodyModal] = useState("");
     const [buttModal, setButtModalConfirm] = useState("Conferma");
    
     //funzioni sele
     const aSeleSoci=aGV.aConfSoci
     const aSeleSedi=aGV.aConfSedi

     //funzioni routing
     function handleBtnCaleEditAggiClick() {  //funzione Aggiorna
         checkCale()
     }
     function handleBtnCaleEditChiuClick() {  //funzione chiudi
         navigate("/Cale");
     }
     function handleBtnCaleEditElimClick() {  //funzione elimina
          setTitoModal("Attenzione")
          setBodyModal("Conferma eliminazione data "+txtCaleEditDatr+"?");
          setCurrOpzi("D")
          //alert("PPPP")
          onOpenModalConfirm()
     }
 
     //funzioni check save
     function checkCale() {   //controllo dati
         var cErro=""
         var cLabe=""
        // alert(txtCaleEditDatx)
         if (txtCaleEditSoci.trim()===''){
             cErro=cErro+("Selezionare società **")
             cLabe=cLabe+"#lblConfCaleEditSoci|"
         }
         if (txtCaleEditSede.trim()===''){
               cErro=cErro+("Selezionare sede **")
               cLabe=cLabe+"#lblConfCaleEditCodx|"
         }
         if (txtCaleEditDatx.trim()===''){
               cErro=cErro+("Digitare data **")
               cLabe=cLabe+"#lblConfCaleEditDesx|" 
         }
         
         if (cErro===''){
               saveCale()
         } else {
               notifyToast(cErro)  
         }
   
     }
     function saveCale() { //salvataggio
          var cData=""
          var cKeyc=""
          if (cCurrOpzi==='I'){ //insert
                cKeyc= "company;branch;dateHoliday|'"+txtCaleEditSoci+"';'"+txtCaleEditSede
                cKeyc=cKeyc+"';'"+convDatyMGA(txtCaleEditDatx,"/")+"'"
                cData= "company;branch;dateHoliday|"
                cData=cData +"'" +txtCaleEditSoci+"';"
                cData=cData +"'" +txtCaleEditSede+"';"
                cData=cData +"'" +convDatyMGA(txtCaleEditDatx,"/")+"'"
               
               
          }      
          if (cCurrOpzi==='U'){ //update non c'e'
               
          }  
          if (cCurrOpzi==='D')  { //elimina
                cKeyc= "company;branch;dateHoliday|'"+txtCaleEditSoci+"';'"+txtCaleEditSede
                cKeyc=cKeyc+"';'"+convDatyMGA(txtCaleEditDatx,"/")+"'"
                cData= "|"
          }
         
          var myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/json");
          var raw = JSON.stringify({
             customer: aGV.customer, //"AMGDEMO", //"cust01",
             user: aGV.user, //"alberto.cappelli@libero.it", //"pippo",
             type: cCurrOpzi,    // UDI
             keyc: cKeyc,
             data: cData,
          });
          var requestOptions = {
             method: "POST",
             headers: myHeaders,
             body: raw,
             redirect: "follow",
          };
          
          const cUrl=aGV.urll+"AMG_Holidays" //"https://amg.datapartners.ch/Amg/ws/AMG_WS/AMG_Roles"
          console.log(cUrl)
          console.log(raw)
          fetch(cUrl,requestOptions)
               //console.log(data)
               .then(response =>  { return response.json()  }) 
               .then( datiricevuti => { 
                    //in datiricevuti.error manda  errori es codice esistente
                    if (datiricevuti.error!=='') { 
                         //console.log(datiricevuti.error)
                         setTitoModal("Attenzione")
                         setBodyModal(convErrorMessage(datiricevuti.error))
                         onOpenModal()
                    } else { 
                          handleBtnCaleEditChiuClick()
                    }      
               })
               .catch( err =>  {  
                    //console.log(err)
                    setTitoModal("Attenzione")
                    setBodyModal(err.message)
                    onOpenModal()
               })
          }   
  return (    
    <div style={window.$stiTop} > 
        <div style={window.$stiLeft}>
            <HomeSide></HomeSide>
        </div> 
        <div style={window.$stiRight} >
           <h2  >AMG Foundation Calendario</h2>
           <hr />
           <h4 >{cCurrOpziTito}</h4>
           <div className="divDummyAlign">
             <ButtonGroup   aria-label="Button Group" className="btnGroup">
               <Button variant="danger" size="sm"  onClick={handleBtnCaleEditAggiClick}
               hidden={cCurrOpzi!=='U' ? false : true}
               >Aggiorna</Button>    
               <Button variant="danger" size="sm"  onClick={handleBtnCaleEditElimClick}
                hidden={cCurrOpzi==='U' ? false : true}
                >Elimina</Button>              
               <Button variant="secondary" size="sm"  onClick={handleBtnCaleEditChiuClick}> Chiudi</Button>
             </ButtonGroup>
           </div>
           <pre></pre>
           <Card className="shadow p-3 mb-5 bg-white rounded">
             <Row >
             <Col className="col-md-4" >      
              <Card.Header className="card-header" >Dati base</Card.Header>  
              <Card.Body className="card-body">  
                 <Form.Group className="form-floating mb-3" >
                     <Form.Select
                         disabled={cCurrOpzi==='U' ? true : false}
                         defaultValue={txtCaleEditSoci}
                         onChange={(event) => setCaleEditSoci(event.target.value)}
                     >
                         <option>...</option>
                         {  aSeleSoci.map((option, index) => {
                         return (<option key={option.id} value={option.CODX}>{option.DESX}</option>)
                         })  }
                     </Form.Select>
                     <Form.Label >Società</Form.Label>
                 </Form.Group>
                 <Form.Group className="form-floating mb-3" >
                     <Form.Select
                         disabled={cCurrOpzi==='U' ? true : false}
                         defaultValue={txtCaleEditSede}
                         onChange={(event) => setCaleEditSede(event.target.value)}
                     >
                         <option>...</option>
                         {  aSeleSedi.map((option, index) => {
                         return (<option key={option.id} value={option.CODX}>{option.DESX}</option>)
                         })  }
                     </Form.Select>
                     <Form.Label >Sede</Form.Label>
                 </Form.Group>
                <Form.Group className="form-floating mb-3" >
                      <Form.Control type="date" value={txtCaleEditDatx}
                      disabled={cCurrOpzi==='U' ? true : false}
                      onChange={(event)=> setCaleEditDatx(event.target.value)}/>
                      <Form.Label >Data</Form.Label>
                 </Form.Group>  
                
                
               </Card.Body> 
            </Col>    
           
         
           
        
           </Row>      
           <ToastContainer />
          
    
           </Card> 
           { openModal &&
                    <ShowModal open={openModal} onClose={onCloseModal} 
                    tito={titoModal} text={bodyModal} 
                    ></ShowModal>
           }   
           { openModalConfirm &&
                    <ShowModalConfirm open={openModalConfirm} onClose={onCloseModalConfirm} 
                    onReturn={onReturnModalConfirm}   
                    tito={titoModal} text={bodyModal} butt={buttModal} 
                    ></ShowModalConfirm>
           }              
     </div>   //div right  
   </div>  //div globale 
  ) //return  
             
}  //componente             

export default CaleEdit;