import './../App.css';
import React, { useState ,useEffect,useContext} from 'react';
import HomeSide from './HomeSide.js';
import { Card,Button,ButtonGroup} from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useNavigate } from "react-router-dom";
import {useLocation} from 'react-router-dom';
import {notifyToast,convErrorMessage} from './Functions.js';
import { ToastContainer } from 'react-toastify';
import { GlobalVariables,convPuntBlank,replApice } from './Functions.js';
import ShowModal from './ShowAlert.js'; 
import ShowModalConfirm from './ShowConfirm.js'; 


const DictEdit = ()  => { //in uselocation.state ho opzi=I U D  indi=ndice  data=record corrente
     //variabili globali
     const aGV= useContext(GlobalVariables)

     //parametri di chiamata  I U D
     const location = useLocation();
     const datx=location.state.data
     
     //cCurrOpzi lo cambio se elimino
     const [cCurrOpzi, setCurrOpzi] = useState(location.state.opzi);
      
     var cCurrOpziTito="Voce: "+datx.IT
     if (cCurrOpzi==='I'){
       cCurrOpziTito="Nuova voce"
       //datx.ID=""
       datx.IT=""
       datx.EN=""
       datx.FR=""
       datx.ES=""
       datx.DE=""
       datx.HTML=""
      
      
     }
     //parametri router
     const navigate = useNavigate(); 
     
     //parametri modal 
     const [openModal, setOpenModal] = useState(false);
     const onOpenModal = () => setOpenModal(true);
     const onCloseModal = () => setOpenModal(false);
    
     //parametri modal confirm
     const [openModalConfirm, setOpenModalConfirm] = useState(false);
     const onOpenModalConfirm = () => setOpenModalConfirm(true);
     const onCloseModalConfirm = () => setOpenModalConfirm(false);
     const onReturnModalConfirm = () => 
     { //conferma alla eliminazione
          setOpenModalConfirm(false);
          //console.log(cCurrOpzi)
          saveDict() 
     }  
     //variabili dell'oggetto
     //se nuovo metto blank altrimento assegno
     const [txtDictEditIt, setDictEditIt] = useState(datx.IT);
     const [txtDictEditEn, setDictEditEn] = useState(datx.EN);
     const [txtDictEditFr, setDictEditFr] = useState(datx.FR);
     const [txtDictEditEs, setDictEditEs] = useState(datx.ES);
     const [txtDictEditDe, setDictEditDe] = useState(datx.DE);
     const [txtDictEditHtml, setDictEditHtml] = useState(datx.HTML);
    
     const [titoModal, setTitoModal] = useState("");
     const [bodyModal, setBodyModal] = useState("");
     const [buttModal, setButtModalConfirm] = useState("Conferma");
    
     //funzioni sele
    // const aSeleSoci=aGV.aConfSoci

     //funzioni routing
     function handleBtnDictEditAggiClick() {  //funzione Aggiorna
         checkDict()
     }
     function handleBtnDictEditChiuClick() {  //funzione chiudi
         navigate("/Dict");
     }
     function handleBtnDictEditElimClick() {  //funzione elimina
          setTitoModal("Attenzione")
          setBodyModal("Conferma eliminazione voce "+txtDictEditIt+"?");
          setCurrOpzi("D")
          //alert("PPPP")
          onOpenModalConfirm()
     }
 
     //funzioni check save
     function checkDict() {   //controllo dati
         var cErro=""
         var cLabe=""
         if (txtDictEditIt.trim()===''){
             cErro=cErro+("Selezionare IT **")
             cLabe=cLabe+"#lblConfDictEditIt|"    
         }
        
         
         if (cErro===''){
               saveDict()
         } else {
               notifyToast(cErro)  
         }
   
     }
     function saveDict() { //salvataggio
          //alert(cCurrOpzi)
          //return
          var cData=""
          var cKeyc=""
          if (cCurrOpzi==='I'){ //insert
                cKeyc= "it|'"+replApice(txtDictEditIt)+"';"
                cData= "it;en;fr;es;de;htmlItems|"
                cData=cData +"'" +replApice(txtDictEditIt)+"';"
                cData=cData +"'" +replApice(txtDictEditEn)+"';"
                cData=cData +"'" +replApice(txtDictEditFr)+"';"
                cData=cData +"'" +replApice(txtDictEditEs)+"';"
                cData=cData +"'" +replApice(txtDictEditDe)+"';"
                cData=cData +"'" +txtDictEditHtml+"';"
               
               
          }      
          if (cCurrOpzi==='U'){ //update
                cKeyc= "it|'"+replApice(txtDictEditIt)+"';"
                cData= "en;fr;es;de;htmlItems|"
                cData=cData +"'" +replApice(txtDictEditEn)+"';"
                cData=cData +"'" +replApice(txtDictEditFr)+"';"
                cData=cData +"'" +replApice(txtDictEditEs)+"';"
                cData=cData +"'" +replApice(txtDictEditDe)+"';"
                cData=cData +"'" +replApice(txtDictEditHtml)+"';"
          }  
          if (cCurrOpzi==='D')  { //elimina
                cKeyc= "it|'"+txtDictEditIt+"';"
               cData= "|"
          }
         
          var myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/json");
          var raw = JSON.stringify({
             customer: aGV.customer, //"AMGDEMO", //"cust01",
             user: aGV.user, //"alberto.cappelli@libero.it", //"pippo",
             type: cCurrOpzi,    // UDI
             keyc: cKeyc,
             data: cData,
          });
          var requestOptions = {
             method: "POST",
             headers: myHeaders,
             body: raw,
             redirect: "follow",
          };
          const cUrl=aGV.urll+"AMG_Dictionaries" //"https://amg.datapartners.ch/Amg/ws/AMG_WS/AMG_Roles"
          fetch(cUrl,requestOptions)
               //console.log(data)
               .then(response =>  { return response.json()  }) 
               .then( datiricevuti => { 
                    //in datiricevuti.error manda  errori es codice esistente
                    if (datiricevuti.error!=='') { 
                         //console.log(datiricevuti.error)
                         setTitoModal("Attenzione")
                         setBodyModal(convErrorMessage(datiricevuti.error))
                         onOpenModal()
                    } else { 
                          handleBtnDictEditChiuClick()
                    }      
               })
               .catch( err =>  {  
                    //console.log(err)
                    setTitoModal("Attenzione")
                    setBodyModal(err.message)
                    onOpenModal()
               })
          }   
  return (    
    <div style={window.$stiTop} > 
        <div style={window.$stiLeft}>
            <HomeSide></HomeSide>
        </div> 
        <div style={window.$stiRight} >
           <h2  >AMG Foundation Sedi</h2>
           <hr />
           <h4 >{cCurrOpziTito}</h4>
           <div className="divDummyAlign">
             <ButtonGroup   aria-label="Button Group" className="btnGroup">
               <Button variant="danger" size="sm"  onClick={handleBtnDictEditAggiClick}>Aggiorna</Button>    
               <Button variant="danger" size="sm"  onClick={handleBtnDictEditElimClick}
                hidden={cCurrOpzi==='U' ? false : true}
                >Elimina</Button>              
               <Button variant="secondary" size="sm"  onClick={handleBtnDictEditChiuClick}> Chiudi</Button>
             </ButtonGroup>
           </div>
           <pre></pre>
           <Card className="shadow p-3 mb-5 bg-white rounded">
             <Row >
             <Col className="col-md-4" >      
              <Card.Header className="card-header" >Dati base</Card.Header>  
              <Card.Body className="card-body">  
                 <Form.Group className="form-floating mb-3" >
                      <Form.Control type="text" value={txtDictEditIt}
                      disabled={cCurrOpzi==='U' ? true : false}
                      onChange={(event)=> setDictEditIt(event.target.value)}/>
                      <Form.Label >Italiano</Form.Label>
                 </Form.Group>  
                 <Form.Group className="form-floating mb-3" >
                      <Form.Control type="text" value={txtDictEditEn}
                      onChange={(event)=> setDictEditEn(event.target.value)}/>
                      <Form.Label >Inglese</Form.Label>
                 </Form.Group>  
                 <Form.Group className="form-floating mb-3" >
                      <Form.Control type="text" value={txtDictEditFr}
                      onChange={(event)=> setDictEditFr(event.target.value)}/>
                      <Form.Label >Francese</Form.Label>
                 </Form.Group>  
                 <Form.Group className="form-floating mb-3" >
                      <Form.Control type="text" value={txtDictEditEs}
                      onChange={(event)=> setDictEditEs(event.target.value)}/>
                      <Form.Label >Spagnolo</Form.Label>
                 </Form.Group>  
                 <Form.Group className="form-floating mb-3" >
                      <Form.Control type="text" value={txtDictEditDe}
                       onChange={(event)=> setDictEditDe(event.target.value)}/>
                      <Form.Label >Tedesco</Form.Label>
                 </Form.Group>  
                
               </Card.Body> 
            </Col>    
            <Col className="col-md-4" >      
              <Card.Header className="card-header" >Oggetti</Card.Header>  
              <Card.Body className="card-body">  
                 <Form.Group className="form-floating mb-3" >
                      <Form.Control type="text"  value={txtDictEditHtml}
                      onChange={(event)=> setDictEditHtml(event.target.value)}/>
                      <Form.Label >Oggetti HTML</Form.Label>
                 </Form.Group>  
                
              </Card.Body> 
            </Col>            
         
           
        
           </Row>      
           <ToastContainer />
          
    
           </Card> 
           { openModal &&
                    <ShowModal open={openModal} onClose={onCloseModal} 
                    tito={titoModal} text={bodyModal} 
                    ></ShowModal>
           }   
           { openModalConfirm &&
                    <ShowModalConfirm open={openModalConfirm} onClose={onCloseModalConfirm} 
                    onReturn={onReturnModalConfirm}   
                    tito={titoModal} text={bodyModal} butt={buttModal} 
                    ></ShowModalConfirm>
           }              
     </div>   //div right  
   </div>  //div globale 
  ) //return  
             
}  //componente             

export default DictEdit;