import Button from 'react-bootstrap/Button';
import { Modal } from 'react-responsive-modal';





//const ShowModal () {
const ShowModalConfirm = (props)  => { 
  //console.log(props)
  //alert("AA")
 // <Modal open={openModal} onClose={onCloseModal} center></Modal>
 
  return (
    <div>
       <Modal 
         open={props.open} 
         onClose={props.onClose } 
         onReturn={props.onReturn } 
         showCloseIcon={false} 
         center>
                <h3 >{props.tito}</h3>
                <hr className="new4"></hr>
               <p>{props.text}</p>
               <Button variant="secondary" size="sm" onClick={props.onClose }>Chiudi</Button>      
               <Button variant="danger" size="sm" style={{"margin": "2px"}} onClick={props.onReturn }>{props.butt}</Button>            
       </Modal>
   </div>
   
  );
};
export default ShowModalConfirm