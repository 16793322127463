import './Home.css';
import React from "react";
import { FaHotel,FaAngleDoubleLeft,FaHome,FaUser,FaArrowCircleLeft,FaCalculator,FaMailBulk,FaUserFriends,FaCog } from 'react-icons/fa';
import { FaHouseUser,FaUserPlus,FaWrench,FaUserSecret,FaWindowRestore,FaGlobe,FaSortAlphaDown,FaCalendar } from 'react-icons/fa';
import {Button,Nav, Navbar} from 'react-bootstrap'; 
import {LinkContainer} from 'react-router-bootstrap'
import { Link } from 'react-router-dom';
import { useState,useEffect } from "react";



const HomeNavbar = props => { 
   
      
 
  return (    
    
  <div id="navHome"> 
     
     <Nav >
        <Navbar.Brand href="">
        </Navbar.Brand>  
        <Nav.Link to="/Home"><FaAngleDoubleLeft></FaAngleDoubleLeft>Torna a cards</Nav.Link>
        <Nav.Link href=""></Nav.Link>
        <Nav.Link href="/Home"><FaHome></FaHome>Home</Nav.Link>
        <Nav.Link href="/Dash"><FaCalculator></FaCalculator>Dashboard</Nav.Link>
        <Nav.Link href="/Soci"><FaHotel></FaHotel>Società</Nav.Link>
        <Nav.Link href="/Sedi"><FaHouseUser></FaHouseUser>Sedi</Nav.Link>
        <Nav.Link href="/Uten" ><FaUser></FaUser>Utenti</Nav.Link>
        <Nav.Link href="/Ruol" ><FaUserFriends></FaUserFriends>Ruoli</Nav.Link>
        <Nav.Link href="/Grup"><FaUserPlus></FaUserPlus>Gruppi</Nav.Link>
        <Nav.Link href="/Tabe"><FaCog></FaCog>Choices</Nav.Link>
        <Nav.Link href="/Aler"><FaMailBulk></FaMailBulk>Alerts</Nav.Link>
        <Nav.Link href="/Cost"><FaWrench></FaWrench>Dati base</Nav.Link>
        <Nav.Link href="/Cale"><FaCalendar></FaCalendar>Calendario</Nav.Link>
        <Nav.Link href="/Dict"><FaSortAlphaDown></FaSortAlphaDown>Dizionario</Nav.Link>
        <Nav.Link href="/Diri"><FaUserSecret></FaUserSecret>Sicurezze</Nav.Link>
        <Nav.Link href="/Card"><FaWindowRestore></FaWindowRestore>Cards</Nav.Link>
       
       
    </Nav>
    
   
  </div>
  


)  
             
}              

    
 

export default HomeNavbar;