import { useState,useEffect } from "react";
import { convBlankPunt } from "./Functions.js";

const useFetchStart=(urx,rex) =>{ 
   
    const [aConfSoci,setConfSoci]=useState([]) ;
    const [aConfTabe,setConfTabe]=useState([]) ;
    const [aConfUten,setConfUten]=useState([]) ;
    const [aConfCard,setConfCard]=useState([]) ;
    const [aConfSedi,setConfSedi]=useState([]) ;
    const [aConfGrup,setConfGrup]=useState([]) ;
    const [aConfRuol,setConfRuol]=useState([]) ;
    const [aConfCost,setConfCost]=useState([]) ;
   
   
  useEffect(() => {
   fetch(urx,rex)
    .then(response =>  { return response.json()  }) 
    .then( data => { 
         var aData=data.startList.companies //data[attx] 
         if(aData!=null){
           var aD=[]
           for (var i = 0; i < aData.length; i++) {
               var row = aData[i];
               //console.log(row)
               if (row!=null) {//console.log(row.code + " - " + row.description);
                  aD.push({
                    id: i,
                    CODX: row.code,
                    DESX: row.description,
                    RIF1: row.rif1,
                    RIF2: row.rif2,
                    RIF3: row.rif3,
                  });
                 
               }   
           } //loop
           setConfSoci(aD)
           //console.log(aD)
           //console.log(data)
      
        } //data is noy null
        var aData=data.startList.users //data[attx] 
        if(aData!=null){
          var aD=[]
          for (var i = 0; i < aData.length; i++) {
              var row = aData[i];
              //console.log(row)
              if (row!=null) {//console.log(row.code + " - " + row.description);
                 aD.push({
                   id: i,
                   EMAI: row.email,
                   DESX: row.description,
                   NICK: row.nickName,
                   LANG: row.language,
                   SOCI: convBlankPunt(row.companies),
                   SEDI: convBlankPunt(row.branches),
                   GRUP: convBlankPunt(row.groups),
                   RUOL: convBlankPunt(row.roles),
                   TELE: row.phone,
                   TEL2: row.phone2,
                   CELL: row.phoneCell,
                   AUT2: row.auth2,
                   RIF1: row.rif1,
                   RIF2: row.rif2,
                   RIF3: row.rif3
                 });
              }   
          } //loop
          setConfUten(aD)
        } //data is noy null
        var aData=data.startList.cards //data[attx] 
        if(aData!=null){
          var aD=[]
          for (var i = 0; i < aData.length; i++) {
              var row = aData[i];
              //console.log(row)
              if (row!=null) {//console.log(row.code + " - " + row.description);
                 aD.push({
                   id: i,
                   CODX: row.code,
                   TITO: row.title,
                   DESX: row.description,
                   HTML: row.html,
                   COLB: row.backcolor,
                   COLF: row.forecolor
                 });
                
              }   
          } //loop
          setConfCard(aD)
        } //data is noy null 
        var aData=data.startList.branches //data[attx] 
        if(aData!=null){
          var aD=[]
          for (var i = 0; i < aData.length; i++) {
              var row = aData[i];
              //console.log(row)
              if (row!=null) {//console.log(row.code + " - " + row.description);
                 aD.push({
                   id: i,
                   SOCI: row.company,
                   CODX: row.code,
                   DESX: row.description,
                   RIF1: row.rif1,
                   RIF2: row.rif2,
                   RIF3: row.rif3
                 });
                
              }   
          } //loop
          setConfSedi(aD)
        } //data is noy null 
        var aData=data.startList.groups //data[attx] 
        if(aData!=null){
          var aD=[]
          for (var i = 0; i < aData.length; i++) {
              var row = aData[i];
              //console.log(row)
              if (row!=null) {//console.log(row.code + " - " + row.description);
                 aD.push({
                   id: i,
                   SOCI: row.company,
                   CODX: row.code,
                   DESX: row.description,
                   RIF1: row.rif1,
                   RIF2: row.rif2,
                   RIF3: row.rif3,
                 });
                
              }   
          } //loop
          setConfGrup(aD)
        } //data is noy null 
        var aData=data.startList.roles //data[attx] 
        if(aData!=null){
          var aD=[]
          for (var i = 0; i < aData.length; i++) {
              var row = aData[i];
              //console.log(row)
              if (row!=null) {//console.log(row.code + " - " + row.description);
                 aD.push({
                   id: i,
                   SOCI: row.company,
                   CODX: row.code,
                   DESX: row.description,
                   RIF1: row.rif1,
                   RIF2: row.rif2,
                   RIF3: row.rif3
                 });
                
              }   
          } //loop
          setConfRuol(aD)
        } //data is noy null 
        var aData=data.startList.baseData //data[attx] 
        if(aData!=null){
          var aD=[]
          for (var i = 0; i < aData.length; i++) {
              var row = aData[i];
              //console.log(row)
              if (row!=null) {//console.log(row.code + " - " + row.description);
                 aD.push({
                   id: i,
                   SOCI: row.company,
                   CODX: row.code,
                   DESX: row.description,
                   VALO: row.itemValue
                 });
                
              }   
          } //loop
          setConfCost(aD)
        } //data is noy null 
        var aData=data.startList.tables //data[attx] 
        if(aData!=null){
          var aD=[]
          for (var i = 0; i < aData.length; i++) {
              var row = aData[i];
              //console.log(row)
              if (row!=null) {//console.log(row.code + " - " + row.description);
                 aD.push({
                   id: i,
                   SOCI: row.company,
                   TABE: row.tableCode,
                   CODX: row.itemCode,
                   DESX: row.itemDescription,
                   VALO: row.itemValue,
                   DEFA: row.defaultValue,
                   COLB: row.backgroundcolor,
                   COLF: row.forecolor,
                   RIF1: row.rif1,
                   RIF2: row.rif2,
                   RIF3: row.rif3
                 });
                
              }   
          } //loop
          setConfTabe(aD)
         
        } //data is noy null 
       
    })
    .catch( err =>  {  
            // setIsPending(false)
            // setError(err.message) 
           // setTitoModal("Attenzione")
           // setBodyModal(err.message)
           // onOpenModal()
     })
},[]);
     

return { aConfSoci,aConfUten,aConfCard,aConfSedi,aConfGrup,aConfRuol,aConfCost,aConfTabe };

};     
export default useFetchStart; 