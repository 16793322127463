import './../App.css';
import React, { useState ,useEffect,useContext} from 'react';
import HomeSide from './HomeSide.js';
import { Card,Button,ButtonGroup} from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useNavigate } from "react-router-dom";
import {useLocation} from 'react-router-dom';
import {notifyToast,convErrorMessage} from './Functions.js';
import { ToastContainer } from 'react-toastify';
import { GlobalVariables,convPuntBlank,replApice } from './Functions.js';
import ShowModal from './ShowAlert.js'; 
import ShowModalConfirm from './ShowConfirm.js'; 


const CardEdit = ()  => { //in uselocation.state ho opzi=I U D  indi=ndice  data=record corrente
     //variabili globali
     const aGV= useContext(GlobalVariables)

     //parametri di chiamata  I U D
     const location = useLocation();
     const datx=location.state.data
     
     //cCurrOpzi lo cambio se elimino
     const [cCurrOpzi, setCurrOpzi] = useState(location.state.opzi);
     
     var cCurrOpziTito="Card: "+datx.CODX
     if (cCurrOpzi==='I'){
       cCurrOpziTito="Nuova card"
       datx.CODX=""
       datx.DESX=""
       datx.TITO=""
       datx.HTML=""
       datx.LINT=""
       datx.CARO=""
       datx.COLB=""
       datx.COLF=""
       datx.RIF1=""
       datx.RIF2=""
       datx.RIF3=""
     }
     //parametri router
     const navigate = useNavigate(); 
     
     //parametri modal 
     const [openModal, setOpenModal] = useState(false);
     const onOpenModal = () => setOpenModal(true);
     const onCloseModal = () => setOpenModal(false);
    
     //parametri modal confirm
     const [openModalConfirm, setOpenModalConfirm] = useState(false);
     const onOpenModalConfirm = () => setOpenModalConfirm(true);
     const onCloseModalConfirm = () => setOpenModalConfirm(false);
     const onReturnModalConfirm = () => 
     { //conferma alla eliminazione
          setOpenModalConfirm(false);
          //console.log(cCurrOpzi)
          saveCard() 
     }  
     //variabili dell'oggetto
     //se nuovo metto blank altrimento assegno
     const [txtCardEditCodx, setCardEditCodx] = useState(datx.CODX);
     const [txtCardEditDesx, setCardEditDesx] = useState(datx.DESX);
     const [txtCardEditTito, setCardEditTito] = useState(datx.TITO);
     const [txtCardEditHtml, setCardEditHtml] = useState(datx.HTML);
     const [txtCardEditColb, setCardEditColb] = useState(datx.COLB);
     const [txtCardEditColf, setCardEditColf] = useState(datx.COLF);
     const [txtCardEditRif1, setCardEditRif1] = useState(datx.RIF1);
     const [txtCardEditRif2, setCardEditRif2] = useState(datx.RIF2);
     const [txtCardEditRif3, setCardEditRif3] = useState(datx.RIF3);
     const [txtCardEditLint, setCardEditLint] = useState(datx.LINT);
     const [txtCardEditCaro, setCardEditCaro] = useState(datx.CARO);
     const [titoModal, setTitoModal] = useState("");
     const [bodyModal, setBodyModal] = useState("");
     const [buttModal, setButtModalConfirm] = useState("Conferma");

     //funzioni sele
     const aSeleLint = [ 
          { "id": "0","CODX": "INTERNAL_LINK","DESX": "INTERNAL_LINK"},
          { "id": "1","CODX": "EXTERNAL_LINK","DESX": "EXTERNAL_LINK"},
          { "id": "2","CODX": "IFRAME_LINK","DESX": "IFRAME_LINK"}
     ]
     const aSeleCaro = [ 
          { "id": "0","CODX": "ALL","DESX": "AMG & PWA"},
          { "id": "1","CODX": "AMG","DESX": "AMG Only"},
          { "id": "2","CODX": "PWA","DESX": "PWA Only"}
     ]
    
     //funzioni routing
     function handleBtnCardEditAggiClick() {  //funzione Aggiorna
         checkCard()
     }
     function handleBtnCardEditChiuClick() {  //funzione chiudi
         navigate("/Card");
     }
     function handleBtnCardEditElimClick() {  //funzione elimina
          setTitoModal("Attenzione")
          setBodyModal("Conferma eliminazione card "+txtCardEditCodx+"?");
          setCurrOpzi("D")
          //alert("PPPP")
          onOpenModalConfirm()
     }
 
     //funzioni check save
     function checkCard() {   //controllo dati
         var cErro=""
         var cLabe=""
         if (txtCardEditCodx.trim()===''){
               cErro=cErro+("Digitare codice **")
               cLabe=cLabe+"#lblConfCardEditCodx|"
         }
         if (txtCardEditDesx.trim()===''){
               cErro=cErro+("Digitare descrizione **")
               cLabe=cLabe+"#lblConfCardEditDesx|" 
         }
         if (txtCardEditCaro.trim()===''){
              cErro=cErro+("Selezionare origine card **")
              cLabe=cLabe+"#lblConfCardEditCaro|"
         }
         if (txtCardEditLint.trim()===''){
             cErro=cErro+("Selezionare tipo link **")
             cLabe=cLabe+"#lblConfCardEditLint|"
         }
         
         if (cErro===''){
               saveCard()
         } else {
               notifyToast(cErro)  
         }
   
     }
     function saveCard() { //salvataggio
          //alert(cCurrOpzi)
          //return
          var cData=""
          var cKeyc=""
          if (cCurrOpzi==='I'){ //insert
                cKeyc= "code|'"+txtCardEditCodx+"'"
                cData= "code;title;description;Html;backcolor;forecolor;rif1;rif2;rif3;cardOrigin;linkType|"
                cData=cData +"'" +txtCardEditCodx+"';"
                cData=cData +"'" +replApice(txtCardEditTito)+"';"
                cData=cData +"'" +replApice(txtCardEditDesx)+"';"
                cData=cData +"'" +txtCardEditHtml+"';"
                cData=cData +"'" +txtCardEditColb+"';"
                cData=cData +"'" +txtCardEditColf+"';"
                cData=cData +"'" +txtCardEditRif1+"';"
                cData=cData +"'" +txtCardEditRif2+"';"
                cData=cData +"'" +txtCardEditRif3+"';"
                cData=cData +"'" +txtCardEditCaro+"';"
                cData=cData +"'" +txtCardEditLint+"'"
          }      
          if (cCurrOpzi==='U'){ //update
                cKeyc= "code|'"+txtCardEditCodx+"'"
                cData= "title;description;Html;backcolor;forecolor;rif1;rif2;rif3;cardOrigin;linkType|"
                cData=cData +"'" +replApice(txtCardEditTito)+"';"
                cData=cData +"'" +replApice(txtCardEditDesx)+"';"
                cData=cData +"'" +txtCardEditHtml+"';"
                cData=cData +"'" +txtCardEditColb+"';"
                cData=cData +"'" +txtCardEditColf+"';"
                cData=cData +"'" +txtCardEditRif1+"';"
                cData=cData +"'" +txtCardEditRif2+"';"
                cData=cData +"'" +txtCardEditRif3+"';"
                cData=cData +"'" +txtCardEditCaro+"';"
                cData=cData +"'" +txtCardEditLint+"'"
          }  
          if (cCurrOpzi==='D')  { //elimina
                cKeyc= "code|'"+txtCardEditCodx+"'"
                cData= "|"
          }
         
          var myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/json");
          var raw = JSON.stringify({
             customer: aGV.customer, //"AMGDEMO", //"cust01",
             user: aGV.user, //"alberto.cappelli@libero.it", //"pippo",
             type: cCurrOpzi,    // UDI
             keyc: cKeyc,
             data: cData,
          });
          var requestOptions = {
             method: "POST",
             headers: myHeaders,
             body: raw,
             redirect: "follow",
          };
          const cUrl=aGV.urll+"AMG_Cards" //"https://amg.datapartners.ch/Amg/ws/AMG_WS/AMG_Roles"
          console.log(raw)
          fetch(cUrl,requestOptions)
               //console.log(data)
               .then(response =>  { return response.json()  }) 
               .then( datiricevuti => { 
                    //in datiricevuti.error manda  errori es codice esistente
                    if (datiricevuti.error!=='') { 
                         //console.log(datiricevuti.error)
                         setTitoModal("Attenzione")
                         setBodyModal(convErrorMessage(datiricevuti.error))
                         onOpenModal()
                    } else { 
                          handleBtnCardEditChiuClick()
                    }      
               })
               .catch( err =>  {  
                    //console.log(err)
                    setTitoModal("Attenzione")
                    setBodyModal(err.message)
                    onOpenModal()
               })
          }   
  return (    
    <div style={window.$stiTop} > 
        <div style={window.$stiLeft}>
            <HomeSide></HomeSide>
        </div> 
        <div style={window.$stiRight} >
           <h2  >AMG Foundation Cards </h2>
           <hr />
           <h4 >{cCurrOpziTito}</h4>
           <div className="divDummyAlign">
             <ButtonGroup   aria-label="Button Group" className="btnGroup">
               <Button variant="danger" size="sm"  onClick={handleBtnCardEditAggiClick}>Aggiorna</Button>    
               <Button variant="danger" size="sm"  onClick={handleBtnCardEditElimClick}
               hidden={cCurrOpzi==='U' ? false : true}
               >Elimina</Button>              
               <Button variant="secondary" size="sm"  onClick={handleBtnCardEditChiuClick}> Chiudi</Button>
             </ButtonGroup>
           </div>
           <pre></pre>
           <Card className="shadow p-3 mb-5 bg-white rounded">
             <Row >
             <Col className="col-md-4" >      
              <Card.Header className="card-header" >Dati base</Card.Header>  
              <Card.Body className="card-body">  
                <Form.Group className="form-floating mb-3" >
                      <Form.Control type="text" value={txtCardEditCodx}
                      disabled={cCurrOpzi==='U' ? true : false}
                      onChange={(event)=> setCardEditCodx(event.target.value)}/>
                      <Form.Label >Codice</Form.Label>
                 </Form.Group>  
                
                 <Form.Group className="form-floating mb-3" >
                      <Form.Control type="text" value={txtCardEditTito}
                       onChange={(event)=> setCardEditTito(event.target.value)}/>
                      <Form.Label >Titolo</Form.Label>
                 </Form.Group>  
                 <Form.Group className="form-floating mb-3" >
                      <Form.Control as="textarea" className="form-control-textarea" value={txtCardEditDesx}
                       onChange={(event)=> setCardEditDesx(event.target.value)}/>
                      <Form.Label >Descrizione (mnemonica)</Form.Label>
                 </Form.Group>  
                 <Form.Group className="form-floating mb-3" >
                     <Form.Select
                         
                         defaultValue={txtCardEditCaro}
                         onChange={(event) => setCardEditCaro(event.target.value)}
                     >
                         <option>...</option>
                         {  aSeleCaro.map((option, index) => {
                         return (<option key={option.id} value={option.CODX}>{option.DESX}</option>)
                         })  }
                     </Form.Select>
                     <Form.Label >Origine card</Form.Label>
                 </Form.Group>
              </Card.Body> 
            </Col>    
            <Col className="col-md-4" >      
              <Card.Header className="card-header" >Dati configurazione</Card.Header>  
              <Card.Body className="card-body">  
                   <Form.Group className="form-floating mb-3" >
                     <Form.Select
                        
                         defaultValue={txtCardEditLint}
                         onChange={(event) => setCardEditLint(event.target.value)}
                     >
                         <option>...</option>
                         {  aSeleLint.map((option, index) => {
                         return (<option key={option.id} value={option.CODX}>{option.DESX}</option>)
                         })  }
                     </Form.Select>
                     <Form.Label >Tipo link<link rel="shortcut icon" href="favicon.ico" type="image/x-icon" /></Form.Label>
                 </Form.Group>
                 <Form.Group className="form-floating mb-3" >
                      <Form.Control type="text"  value={txtCardEditHtml}
                      onChange={(event)=> setCardEditHtml(event.target.value)}/>
                      <Form.Label >Link</Form.Label>
                 </Form.Group>  
                 <Form.Group className="form-floating mb-3" >
                      <Form.Control type="text" value={txtCardEditColb}
                      onChange={(event)=> setCardEditColb(event.target.value)}/>
                      <Form.Label >Background color</Form.Label>
                 </Form.Group>  
                 <Form.Group className="form-floating mb-3" >
                      <Form.Control type="text" value={txtCardEditColf}
                      onChange={(event)=> setCardEditColf(event.target.value)}/>
                      <Form.Label >Foreground color</Form.Label>
                 </Form.Group>  
               
               
              </Card.Body> 
            </Col>                    
            <Col className="col-md-4" >      
              <Card.Header className="card-header" >Dati addizionali</Card.Header>  
              <Card.Body className="card-body">  
                 <Form.Group className="form-floating mb-3" >
                      <Form.Control type="text"  value={txtCardEditRif1}
                      onChange={(event)=> setCardEditRif1(event.target.value)}/>
                      <Form.Label >Add.1</Form.Label>
                 </Form.Group>  
                 <Form.Group className="form-floating mb-3" >
                      <Form.Control type="text" value={txtCardEditRif2}
                      onChange={(event)=> setCardEditRif2(event.target.value)}/>
                      <Form.Label >Add.2</Form.Label>
                 </Form.Group>  
                 <Form.Group className="form-floating mb-3" >
                      <Form.Control type="text" value={txtCardEditRif3}
                      onChange={(event)=> setCardEditRif3(event.target.value)}/>
                      <Form.Label >Add.3</Form.Label>
                 </Form.Group>  
               
              </Card.Body> 
            </Col>            
           
        
           </Row>      
           <ToastContainer />
          
    
           </Card> 
           { openModal &&
                    <ShowModal open={openModal} onClose={onCloseModal} 
                    tito={titoModal} text={bodyModal} 
                    ></ShowModal>
           }   
           { openModalConfirm &&
                    <ShowModalConfirm open={openModalConfirm} onClose={onCloseModalConfirm} 
                    onReturn={onReturnModalConfirm}   
                    tito={titoModal} text={bodyModal} butt={buttModal} 
                    ></ShowModalConfirm>
           }              
     </div>   
   </div>  //div globale 
  ) //return  
             
}  //componente             

export default CardEdit;