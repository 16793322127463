import './../App.css';
import React, { useState ,useEffect,useContext} from 'react';
import HomeSide from './HomeSide.js';
import { Card,Button,ButtonGroup} from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useNavigate } from "react-router-dom";
import {useLocation} from 'react-router-dom';
import {notifyToast,convErrorMessage} from './Functions.js';
import { ToastContainer } from 'react-toastify';
import { GlobalVariables,convPuntBlank,replApice } from './Functions.js';
import ShowModal from './ShowAlert.js'; 
import ShowModalConfirm from './ShowConfirm.js'; 


const AlerEdit = ()  => { //in uselocation.state ho opzi=I U D  indi=ndice  data=record corrente
     //variabili globali
     const aGV= useContext(GlobalVariables)

     //parametri di chiamata  I U D
     const location = useLocation();
     const datx=location.state.data
     //cCurrOpzi lo cambio se elimino
     const [cCurrOpzi, setCurrOpzi] = useState(location.state.opzi);
     //var cCurrOpzi=location.state.opzi
     var cCurrOpziTito="Alert: "+datx.CODX
     if (cCurrOpzi==='I'){
       cCurrOpziTito="Nuovo alert"
       datx.SOCI=""
       datx.CODX=""
       datx.DESX=""
       datx.STEP=""
       datx.TYPE=""
       datx.TO=""
       datx.CC=""
       datx.SUBJ=""
       datx.BODY=""
       datx.RIF1=""
       datx.RIF2=""
       datx.RIF3=""
     }
     //parametri router
     const navigate = useNavigate(); 
     
     //parametri modal 
     const [openModal, setOpenModal] = useState(false);
     const onOpenModal = () => setOpenModal(true);
     const onCloseModal = () => setOpenModal(false);
    
    
    
     //parametri modal confirm
     const [openModalConfirm, setOpenModalConfirm] = useState(false);
     const onOpenModalConfirm = () => setOpenModalConfirm(true);
     const onCloseModalConfirm = () => setOpenModalConfirm(false);
     const onReturnModalConfirm = () => 
     { //conferma alla eliminazione
          setOpenModalConfirm(false);
          console.log(cCurrOpzi)
          saveAler() 
     }  

     //select 
 

     //variabili dell'oggetto
     //se nuo metto blank altrimento assegno
     const [txtAlerEditSoci, setAlerEditSoci] = useState(datx.SOCI);
     const [txtAlerEditCodx, setAlerEditCodx] = useState(datx.CODX);
     const [txtAlerEditDesx, setAlerEditDesx] = useState(datx.DESX);
     const [txtAlerEditStep, setAlerEditStep] = useState(datx.STEP);
     const [txtAlerEditType, setAlerEditType] = useState(datx.TYPE);
     const [txtAlerEditTo, setAlerEditTo] = useState(datx.TO);
     const [txtAlerEditCc, setAlerEditCc] = useState(datx.CC);
     const [txtAlerEditSubj, setAlerEditSubj] = useState(datx.SUBJ);
     const [txtAlerEditBody, setAlerEditBody] = useState(datx.BODY);
     const [txtAlerEditRif1, setAlerEditRif1] = useState(datx.RIF1);
     const [txtAlerEditRif2, setAlerEditRif2] = useState(datx.RIF2);
     const [txtAlerEditRif3, setAlerEditRif3] = useState(datx.RIF3);
     const [titoModal, setTitoModal] = useState("");
     const [bodyModal, setBodyModal] = useState("");
     const [buttModal, setButtModalConfirm] = useState("Conferma");
     //alert(txtAlerEditSoci)
     //creazione tabe per tipi
     const aSeleSoci=aGV.aConfSoci
     //console.log(aGV.aConfSoci)
     const aSeleTabe=[ 
          { id:0, CODX: 'MAIL', DESX:'MAIL' },
          { id:1, CODX: 'SMS', DESX:'SMS' }
     ];
    
     //aggiunte e filtri per select
     //const cDummy={  id:-1,CODX:'...',DESX:'...'}
     //aSeleSoci.splice(0,0, cDummy)
     //aSeleTabe.splice(0,0, cDummy)
    // console.log(aSeleTabe)
     //const aTabeSele = aGV.aConfTabe.filter(table =>table.TABE==='AUT2');
     
    
     //funzioni routing
     function handleBtnAlerEditAggiClick() {  //funzione Aggiorna
         checkAler()
     }
     function handleBtnAlerEditChiuClick() {  //funzione chiudi
         navigate("/Aler");
     }
     function handleBtnAlerEditElimClick() {  //funzione elimina
          setTitoModal("Attenzione")
          setBodyModal("Conferma eliminazione alert "+txtAlerEditCodx+"?");
          setCurrOpzi("D")
          //alert("PPPP")
          onOpenModalConfirm()
      }

    
               //esempi di fine salvataggio ma poi li togliamo  
               //setTitoModal("Info")
               //setBodyModal("Dati archiviati con successo".)
               //onOpenModal()
               //setOpenModal(true);  //equivalente a sopra openmodal
                    
    
     //funzioni check save
     function checkAler() {   //controllo dati
         var cErro=""
         var cLabe=""
         if (convPuntBlank(txtAlerEditSoci.trim())===''){
               cErro=cErro+("Digitare società **")
               cLabe=cLabe+"#lblConfAlerEditSoci|" //per ora non usata 
         }
         if (txtAlerEditCodx.trim()===''){
               cErro=cErro+("Digitare codice **")
               cLabe=cLabe+"#lblConfAlerEditCodx|"
         }
         if (txtAlerEditDesx.trim()===''){
               cErro=cErro+("Digitare descrizione **")
               cLabe=cLabe+"#lblConfAlerEditDesx|" 
         }
         if (txtAlerEditStep.trim()===''){
          cErro=cErro+("Digitare step **")
          cLabe=cLabe+"#lblConfAlerEditStep|" 
         }
         if (convPuntBlank(txtAlerEditType.trim())===''){
                cErro=cErro+("Selezionare tipo **")
                cLabe=cLabe+"#lblConfAlerEditType|" 
         }
         if (cErro===''){
               saveAler()
         } else {
               notifyToast(cErro)  
         }
   
     }
     function saveAler() { //salvataggio
          //alert(cCurrOpzi)
          //return
          var cData=""
          var cKeyc=""
          if (cCurrOpzi==='I'){ //insert
                cKeyc= "company;code|'"+txtAlerEditSoci+"';'"+txtAlerEditCodx+"'"
                cData= "company;code;description;step;type;too;cc;subject;body;rif1;rif2;rif3|"
                cData=cData +"'" +txtAlerEditSoci+"';"
                cData=cData +"'" +txtAlerEditCodx+"';"
                cData=cData +"'" +replApice(txtAlerEditDesx)+"';"
                cData=cData +"'" +txtAlerEditStep+"';"
                cData=cData +"'" +txtAlerEditType+"';"
                cData=cData +"'" +txtAlerEditTo+"';"
                cData=cData +"'" +txtAlerEditCc+"';"
                cData=cData +"'" +replApice(txtAlerEditSubj)+"';"
                cData=cData +"'" +replApice(txtAlerEditBody)+"';"
                cData=cData +"'" +txtAlerEditRif1+"';"
                cData=cData +"'" +txtAlerEditRif2+"';"
                cData=cData +"'" +txtAlerEditRif3+"'"
          }      
          if (cCurrOpzi==='U'){ //update
                cKeyc= "company;code|'"+txtAlerEditSoci+"';'"+txtAlerEditCodx+"'"
                cData= "description;step;type;too;cc;subject;body;rif1;rif2;rif3|"
                cData=cData +"'" +replApice(txtAlerEditDesx)+"';"
                cData=cData +"'" +txtAlerEditStep+"';"
                cData=cData +"'" +txtAlerEditType+"';"
                cData=cData +"'" +txtAlerEditTo+"';"
                cData=cData +"'" +txtAlerEditCc+"';"
                cData=cData +"'" +replApice(txtAlerEditSubj)+"';"
                cData=cData +"'" +replApice(txtAlerEditBody)+"';"
                cData=cData +"'" +txtAlerEditRif1+"';"
                cData=cData +"'" +txtAlerEditRif2+"';"
                cData=cData +"'" +txtAlerEditRif3+"'"
          }  
          if (cCurrOpzi==='D')  { //elimina
                cKeyc= "company;code|'"+txtAlerEditSoci+"';'"+txtAlerEditCodx+"'"
                cData= "|"
          }
         
          var myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/json");
          var raw = JSON.stringify({
             customer: aGV.customer, //"AMGDEMO", //"cust01",
             user: aGV.user, //"alberto.cappelli@libero.it", //"pippo",
             type: cCurrOpzi,    // UDI
             keyc: cKeyc,
             data: cData,
          });
          var requestOptions = {
             method: "POST",
             headers: myHeaders,
             body: raw,
             redirect: "follow",
          };
          const cUrl=aGV.urll+"AMG_Alerts" //"https://amg.datapartners.ch/Amg/ws/AMG_WS/AMG_Roles"
          //console.log(cUrl)    
          //return
          // console.log(cUrl)
          //console.log(raw)
          //return
          //console.log(requestOptions)
             
          fetch(cUrl,requestOptions)
               //console.log(data)
               .then(response =>  { return response.json()  }) 
               .then( datiricevuti => { 
                    //in datiricevuti.error manda  errori es codice esistente
                    if (datiricevuti.error!=='') { 
                         //console.log(datiricevuti.error)
                         setTitoModal("Attenzione")
                         setBodyModal(convErrorMessage(datiricevuti.error))
                         //alert("PPPP")
                         onOpenModal()
                    } else { 
                          handleBtnAlerEditChiuClick()
                    }      
               })
               .catch( err =>  {  
                    //console.log(err)
                    setTitoModal("Attenzione")
                    setBodyModal(err.message)
                    //alert("PPPP")
                    onOpenModal()
               })
          }   
  return (    
    <div style={window.$stiTop} > 
        <div style={window.$stiLeft}>
            <HomeSide></HomeSide>
        </div> 
        <div style={window.$stiRight} >
           <h2  >AMG Foundation Alerts </h2>
           <hr />
           <h4 >{cCurrOpziTito}</h4>
          
           <div className="divDummyAlign">
           <ButtonGroup   aria-label="Button Group" className="btnGroup">
               <Button variant="danger" size="sm"  onClick={handleBtnAlerEditAggiClick}>Aggiorna</Button>    
               <Button variant="danger" size="sm"  onClick={handleBtnAlerEditElimClick}
               hidden={cCurrOpzi==='U' ? false : true}
               >Elimina</Button>              
               <Button variant="secondary" size="sm"  onClick={handleBtnAlerEditChiuClick}> Chiudi</Button>
           </ButtonGroup>
           </div>
           <pre></pre>
           <Card className="shadow p-3 mb-5 bg-white rounded">
           <Row >
           <Col className="col-md-4" >      
              <Card.Header className="card-header" >Dati base</Card.Header>  
              <Card.Body className="card-body">  
                 <Form.Group className="form-floating mb-3" >
                     <Form.Select
                         disabled={cCurrOpzi==='U' ? true : false}
                         defaultValue={txtAlerEditSoci}
                         onChange={(event) => setAlerEditSoci(event.target.value)}
                     >
                         <option>...</option>
                         {  aSeleSoci.map((option, index) => {
                         return (<option key={option.id} value={option.CODX}>{option.DESX}</option>)
                         })  }
                     </Form.Select>
                     <Form.Label >Società</Form.Label>
                 </Form.Group>
                 <Form.Group className="form-floating mb-3" >
                      <Form.Control type="text" value={txtAlerEditCodx}
                      disabled={cCurrOpzi==='U' ? true : false}
                      onChange={(event)=> setAlerEditCodx(event.target.value)}/>
                      <Form.Label >Codice</Form.Label>
                 </Form.Group>  
                 <Form.Group className="form-floating mb-3" >
                      <Form.Control type="text" value={txtAlerEditDesx}
                       onChange={(event)=> setAlerEditDesx(event.target.value)}/>
                      <Form.Label >Descrizione (mnemonica)</Form.Label>
                 </Form.Group>  
                 <Form.Group className="form-floating mb-3" >
                      <Form.Control type="text" value={txtAlerEditStep}
                       onChange={(event)=> setAlerEditStep(event.target.value)}/>
                      <Form.Label >Step</Form.Label>
                 </Form.Group>  
                 <Form.Group className="form-floating mb-3" >
                     <Form.Select  
                      defaultValue={txtAlerEditType}
                      onChange={(event) => setAlerEditType(event.target.value)}
                     >
                          <option>...</option>
                         {  aSeleTabe.map((option, index) => {
                         return (<option key={option.id} value={option.CODX}>{option.DESX}</option>)
                         })  }
                     </Form.Select>
                     <Form.Label >Tipo</Form.Label>
                 </Form.Group>.
                
              </Card.Body> 
            </Col>    
            <Col className="col-md-4" >      
              <Card.Header className="card-header" >Dati messaggio</Card.Header>  
              <Card.Body className="card-body">  
                 <Form.Group className="form-floating mb-3" >
                      <Form.Control type="text"  value={txtAlerEditTo}
                      onChange={(event)=> setAlerEditTo(event.target.value)}/>
                      <Form.Label >To</Form.Label>
                 </Form.Group>  
                 <Form.Group className="form-floating mb-3" >
                      <Form.Control type="text" value={txtAlerEditCc}
                      onChange={(event)=> setAlerEditCc(event.target.value)}/>
                      <Form.Label >Cc</Form.Label>
                 </Form.Group>  
                 <Form.Group className="form-floating mb-3" >
                      <Form.Control type="text" value={txtAlerEditSubj}
                      onChange={(event)=> setAlerEditSubj(event.target.value)}/>
                      <Form.Label >Soggetto</Form.Label>
                 </Form.Group>  
                 <Form.Group className="form-floating mb-3" >
                      <Form.Control type="text" value={txtAlerEditBody}
                      onChange={(event)=> setAlerEditBody(event.target.value)}/>
                      <Form.Label >Corpo</Form.Label>
                 </Form.Group>  
               
              </Card.Body> 
            </Col>                    
            <Col className="col-md-4" >      
              <Card.Header className="card-header" >Dati addizionali</Card.Header>  
              <Card.Body className="card-body">  
                 <Form.Group className="form-floating mb-3" >
                      <Form.Control type="text"  value={txtAlerEditRif1}
                      onChange={(event)=> setAlerEditRif1(event.target.value)}/>
                      <Form.Label >Add.1</Form.Label>
                 </Form.Group>  
                 <Form.Group className="form-floating mb-3" >
                      <Form.Control type="text" value={txtAlerEditRif2}
                      onChange={(event)=> setAlerEditRif2(event.target.value)}/>
                      <Form.Label >Add.2</Form.Label>
                 </Form.Group>  
                 <Form.Group className="form-floating mb-3" >
                      <Form.Control type="text" value={txtAlerEditRif3}
                      onChange={(event)=> setAlerEditRif3(event.target.value)}/>
                      <Form.Label >Add.3</Form.Label>
                 </Form.Group>  
               
              </Card.Body> 
            </Col>            
           
        
           </Row>      
           <ToastContainer />
          
    
           </Card> 
           { openModal &&
                    <ShowModal open={openModal} onClose={onCloseModal} 
                    tito={titoModal} text={bodyModal} 
                    ></ShowModal>
           }   
           { openModalConfirm &&
                    <ShowModalConfirm open={openModalConfirm} onClose={onCloseModalConfirm} 
                    onReturn={onReturnModalConfirm}   
                    tito={titoModal} text={bodyModal} butt={buttModal} 
                    ></ShowModalConfirm>
           }              

         
     </div>     
   

</div>  

  )  
             
}              

    
 

export default AlerEdit;