import './../App.css';
import Button from 'react-bootstrap/Button';
import { Modal } from 'react-responsive-modal';
import React, { useState ,useEffect,useContext} from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { GlobalVariables,lenReducer } from './Functions.js';
import { FaCheck } from 'react-icons/fa';
import paginationFactory from 'react-bootstrap-table2-paginator';





//const ShowModal () {
const ShowLook = (props)  => { 
 
  //variabili globali
  const aGV= useContext(GlobalVariables)
  
  //definizioni per tabella
  const [aLookTabe,setLookTabe]=useState([]) ;
 
 
function handleTabeEditClick(opzx,rowx){// 
  const datx=aLookTabe[rowx]
  //console.log(datx)
  props.onClose()
 
}
function iconTable(cell, row, rowIndex) {
  return (
    <div>
    <button id="rowModi"  onClick={() => handleTabeEditClick("U",rowIndex)} 
    className="btn btn-default btn-sm " > <FaCheck></FaCheck></button>
    
    </div>   
  );
}
const options = {
  hideSizePerPage:true
};

var raw=""
var cUrl=""
var cOggx="" //oggetto da leggere
var columns=[]
if (props.step=='UTENEDITPRIV'){   
      columns = [
        {  dataField: "OPZI", text: "Action", sort: false, formatter: iconTable,
           headerStyle: (colum, colIndex) => {return { width: '5%', textAlign: 'center' }; }
        },
        { dataField: "DATC", text: "Data/ora evento"},
        { dataField: "STEP", text: "Evento"} 
       ]      
       cUrl=aGV.urll +"AMG_Logs/SearchLogs" //
       cOggx="logs"
       raw = JSON.stringify({
           customer: aGV.customer, //"AMGDEMO", //"cust01",
           user: aGV.user, // "alberto.cappelli@libero.it", //"pippo",
           userEmail: props.para1,
           query:"*",
           data1: "01/01/1901",
           data2: "12/31/2199",
           step: "FOUND_MODPRIVACY" //"UTENMODIPRIV"
          
        });
};
var myHeaders = new Headers();
myHeaders.append("Content-Type", "application/json");
var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
};
//console.log(cUrl)
console.log(raw)
//return
useEffect(() => {
    fetch(cUrl,requestOptions)
    .then(response =>  { return response.json()  }) 
    .then( data => { 
             
             const aData=data[cOggx] //data[attx] 
            // console.log(aData)
             if(aData!=null){
               //console.log(aData.length)
               var aD=[]
               for (var i = 0; i < aData.length; i++) {
                    var row = aData[i];
                    if (row!=null) {
                      if (props.step=='UTENEDITPRIV'){   
                        aD.push({
                            id: i,
                            DATC: row.dateCreation,
                            STEP: row.step
                            
                        });
                      }    
                    }   
               } //loop
               setLookTabe(aD)
             } //data is noy null
    })
    .catch( err =>  {  
            alert(err.message)
    })
},[]);


 



  return (
    <div >
       <Modal DialogClassName="showLook"
       
         open={props.open} 
         onClose={props.onClose } 
         showCloseIcon={false} 
         center>
                <h3 >{props.tito}</h3>
                <hr className="new4"></hr>
                <BootstrapTable
                   bootstrap4
                   keyField="id" 
                   data={aLookTabe}
                   columns={columns}
                   headerClasses="table tableheadergrey"
                   hover
                   condensed
                   pagination={ paginationFactory(options) }

                />
               <Button variant="secondary" size="sm"  onClick={props.onClose }>Chiudi</Button>         
       </Modal>
   </div>
   
  );
};
export default ShowLook